import React from 'react';

const PolitiqueConfidentialite = () => {
  return (
    <div className="w-full p-16 bg-camel flex flex-col ">
      <h1 className="text-3xl font-bold  text-oceanH1 py-6">Politique de confidentialité</h1>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4 text-oceanH1 py-6">I. Propriété intellectuelle</h2>
        <div className="mb-4">
          <h3 className="text-xl font-bold mb-2">A. Droits d’auteur</h3>
          <p>
            MARGOT BATOUFFLET EI conserve la propriété intellectuelle pleine et exclusive, ainsi que les droits d’auteur, sur tous les éléments accessibles sur son site web, ses brochures commerciales et autres supports de communication, quelle que soit leur nature : graphique, textuelle ou audio, ainsi que les logiciels.
          </p>
          <p>
            L’intégralité de ce site est assujettie à la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. Toute copie, reproduction, représentation, diffusion, modification ou traduction partielle ou totale est interdite sans l’autorisation expresse de MARGOT BATOUFFLET. Dans le cas du non-respect de cette clause, s’expose à des poursuites judiciaires pour délit de contrefaçon.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">B. Marque déposée</h3>
          <p>
            La marque “Joy Blast” déposée est la propriété exclusive de MARGOT BATOUFFLET EI et/ou de ses filiales. Elle est protégée dans sa forme verbale par les lois nationales et internationales sur les marques déposées. Toute utilisation, reproduction ou distribution non autorisée de la marque “Joy Blast” ou de tout contenu associé est strictement interdite sans le consentement écrit préalable de MARGOT BATOUFFLET EI. Dans le cas contraire, MARGOT BATOUFFLET EI est en droit d’engager des poursuites judiciaires conformément aux lois applicables.
          </p>
         
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4 text-oceanH1 py-6">II. Collecte de données personnelles</h2>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">A. Politique de confidentialité</h3>
          <p>
            MARGOT BATOUFFLET EI accorde une grande importance à la protection de vos informations personnelles et s’engage à respecter la réglementation en vigueur applicable au traitement des données à caractère personnel.
          </p>
          <ul className="list-disc pl-8">
            <li>RGPD 2016/679 du Parlement européen et du Conseil du 27 avril 2016,</li>
            <li>Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 modifiée par la LOI n° 2018-493 du 20 juin 2018,</li>
            <li>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique (LCEN).</li>
          </ul>
          <p>
            <strong>Modification de la politique de confidentialité:</strong> MARGOT BATOUFFLET EI se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications prendront effet immédiatement après leur publication sur le site.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">B. Les données collectées</h3>
          <p>
            MARGOT BATOUFFLET EI collecte et traite les données personnelles que vous fournissez volontairement, telles que votre nom, prénom(s), adresse e-mail et numéro de téléphone, lorsque vous utilisez les services, remplissez des formulaires ou communiquez avec elle.
          </p>
          <p>
            <strong>Définition des données personnelles:</strong> ce sont des informations permettant d’identifier directement ou indirectement une personne physique, à savoir ici le Client ou l’Utilisateur.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2">C. Engagements sur les données</h3>
          <p>
            MARGOT BATOUFFLET EI s’engage dans le cadre de la collecte à :
          </p>
          <ul className="list-disc pl-8">
            <li>ne recourir à aucun partenaire commercial ou sous-traitant,</li>
            <li>ne jamais communiquer ces données à des tiers autres, sauf accord express préalable du Client et de l’Utilisateur,</li>
            <li>traiter ces données de façon non-discriminatoire ou contraire à la législation ou la règlementation en vigueur, à l’ordre public et aux bonnes mœurs,</li>
            <li>assurer un suivi des données personnelles qu’elle conserve.</li>
          </ul>
          <p>
            <strong>Limite d’engagement:</strong> MARGOT BATOUFFLET EI étant une micro-entreprise, le Client et l’Utilisateur sont informés que cette dernière n’est pas tenue à une obligation de registre renforcé des activités de traitement effectuées sous sa responsabilité.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">C. Conservation des données</h3>
          <p>
            Les données collectées peuvent être conservées et stockées temporairement à l’aide d’outils numériques tels que : Outlook, Gmail, Notion, Mailchimp.
          </p>
          <p>
            Les données personnelles sont conservées pendant une durée de :
          </p>
          <ul className="list-disc pl-8">
            <li>3 ans si le Client ou l’Utilisateur est inactif depuis cette durée ou si les données sont traitées en dehors de l’Union Européenne,</li>
            <li>13 mois si l’Utilisateur du site refuse de renouveler son consentement pour le traitement des cookies (délai de réitération obligatoire incombant à l’entreprise),</li>
            <li>1 mois à compter de toute demande de rectification, de suppression ou de restitution des données émise par le Client ou l’Utilisateur, ce délai est de 3 mois en cas de demande complexe (Exemple : demande concernant l’intégralité des données) et de 8 jours pour des données de santé,</li>
            <li>10 ans pour les données contenues dans les pièces et justificatifs comptables à compter de la clôture de l’exercice. MARGOT BATOUFFLET EI s’engage à conserver tout contact supprimé dans un registre afin de ne plus le solliciter et de pouvoir justifier des opérations réalisées antérieurement relatives à ses données.</li>
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">D. Finalités de la collecte de données</h3>
          <p>
            Le site <a href="https://joyblast-coaching.com" className="text-blue-500 underline">https://joyblast-coaching.com</a> est géré en toute conformité avec les dispositions du RGPD. Les données personnelles recueillies sur le Client ou l’Utilisateur que peut être amenée à collecter MARGOT BATOUFFLET EI à partir de ce dernier, de façon directe ou indirecte, rentre dans le cadre de son activité et sont traitées dans le cadre des finalités suivantes :
          </p>
          <ul className="list-disc pl-8">
            <li><strong>Emails:</strong> les données personnelles recueillies sur le site résultent de la communication volontaire d'une adresse de courrier électronique. Les adresses e-mail ainsi recueillies ne servent qu'à transmettre les éléments d'information demandés. Les adresses électroniques collectées ne feront l'objet d'aucune cession à des tiers.</li>
            <li><strong>Les données de trafic:</strong> les données de trafic ont uniquement pour finalité de permettre d'analyser la fréquentation des pages d'information sur site afin d'en améliorer le contenu.</li>
            <li><strong>Cookies:</strong> les données relatives à la navigation des visiteurs ne sont pas exploitées nominativement. Il s'agit de statistiques agrégées permettant de connaître les pages les plus et les moins populaires, les chemins préférés, les niveaux d'activité par jour de la semaine et par heure de la journée, les principales erreurs clients ou serveur, etc…</li>
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">E. Sécurité des données</h3>
          <p>
            MARGOT BATOUFFLET EI s’engage à mettre en œuvre tous les moyens nécessaires pour préserver la sécurité et la confidentialité des données personnelles recueillies, contre tout accès non autorisé, altération, divulgation, ou destruction. Et, en cas de violation avérée, MARGOT BATOUFFLET EI s’engage à aviser le Client et l’Utilisateur dès sa découverte.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">F. Droit d’accès et de rectification</h3>
          <p>
            Conformément au RGPD et à l'article 34 de la loi 78-17 du 6 janvier 1978 relative à l'information, aux fichiers et aux libertés, vous disposez du droit d'accéder à vos données personnelles, de les rectifier, de les supprimer, de restreindre leur traitement, de vous opposer au traitement et à la portabilité des données. Pour exercer vos droits ou pour toute information concernant vos données personnelles, vous pouvez nous contacter par courrier à l'adresse suivante ou par email à l’adresse suivante : <a href="mailto:joyblast.consulting@gmail.com" className="text-blue-500 underline">joyblast.consulting@gmail.com</a>
          </p>
          <p>
            En cas de désaccord persistant concernant vos données, vous avez le droit de saisir la CNIL à l’adresse suivante :
          </p>
          <p>
            Commission Nationale Informatique et Libertés<br />
            3, place de Fontenoy, 75007 Paris<br />
            N° de téléphone : 01 53 73 22 22<br />
            Ou en utilisant le lien suivant : <a href="https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil" className="text-blue-500 underline">https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil</a>
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">G. Cookies</h3>
          <p>
            <strong>Fonctionnement des cookies:</strong> lors de cette visite sur le site <a href="https://joyblast-coaching.com" className="text-blue-500 underline">https://joyblast-coaching.com</a>, MARGOT BATOUFFLET EI peut enregistrer des “cookies”, sous réserve de vos choix, sur votre ordinateur qui ont pour but de stocker des informations relatives à votre navigation sur le site (les pages que vous avez consultées, la date et l'heure de la consultation, etc.). Ces cookies ne permettent pas de vous identifier. La durée de conservation de ces informations dans votre ordinateur est d’une durée de 13 mois maximum.
          </p>
          <strong>Désactivation des cookies:</strong> vous pouvez vous opposer à l'enregistrement des cookies en choisissant de supprimer ou désactiver les cookies au niveau de votre navigateur. Chaque navigateur propose une solution de paramétrage pour vous signaler les cookies qui sont déposés dans votre ordinateur et vous permettre des les accepter ou de les refuser, au cas par cas systématiquement.
          <p>
            <strong>Configuration:</strong> la configuration de chaque navigateur est différente. Nous vous invitons à vous reporter au menu d’aide de votre navigateur (Microsoft Internet Explorer ou Mozilla Firefox ou Google Chrome ou Safari ou Opéra) pour paramétrer celui-ci selon vos souhaits.
          </p>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4 text-oceanH1 py-6">III. Limitations de responsabilité</h2>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">A. Responsabilité pour le contenu du site</h3>
          <p>
            MARGOT BATOUFFLET EI ne pourra être tenue responsable pour toute donnée qui s’avèrerait être obsolète, inexacte ou faisant défaut. Toute rédaction de contenus est soumise à un travail préalable de recherches minutieuses et approfondies. Cependant, les données et informations mises en ligne via le site <a href="https://joyblast-coaching.com" className="text-blue-500 underline">https://joyblast-coaching.com</a> et ses partenaires étant sujettes à des modifications et évolutions.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">B. Responsabilité pour le fonctionnement du site</h3>
          <p>
            MARGOT BATOUFFLET EI ne pourra être tenue responsable de toutes interruptions, inaccessibilités, dysfonctionnements ou plus généralement de tout dommage direct ou indirect, occasionné par l’utilisation ou la consultation du site ou de tout autre service proposé. Cela quelle que puisse en être les causes, l’origine, les conséquences ou la nature (indisponibilité temporaire du site, virus informatique, etc).
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">C. Responsabilité après la réalisation de la prestation</h3>
          <p>
            MARGOT BATOUFFLET EI ne saurait être tenue responsable des dommages et préjudices que le Client peut connaître après la réalisation de la prestation. Cela s’applique en cas d’éventuels dommages et préjudices, moraux ou financiers, liés aux actions du Client mises en place à la suite de la réalisation de la prestation.
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2 text-oceanH1 py-6">D. Responsabilité après la réalisation de la prestation</h3>
          <p>
            MARGOT BATOUFFLET EI ne saurait être tenue responsable d’un dommage résultant d’une négligence ou d’une faute imputable au Client ou à un tiers au présent contrat. Cela quelle que puisse en être les conséquences : manque à gagner, manque de chance, actions éventuellement intentées par des tiers à l’encontre du Client, etc.
          </p>
        </div>
      </div>

      <div>
        <h2 className="text-2xl font-bold mb-4 text-oceanH1 py-6">IV. Modifications des mentions légales</h2>
        <p>
          MARGOT BATOUFFLET EI s’engage :
        </p>
        <ul className="list-disc pl-8">
          <li>A informer les utilisateurs à l'avance de toute modification significative des mentions légales. Cela peut être fait par le biais d'une notification sur le site, par e-mail, ou par d'autres moyens de communication appropriés.</li>
          <li>Si la législation le requiert, MARGOT BATOUFFLET EI demandera explicitement le consentement des utilisateurs pour les nouvelles conditions.</li>
          <li>A partager la date de la dernière mise à jour.</li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4 text-oceanH1 py-6">V. Autres liens utiles</h2>
        <a alt='conditions_generales_de_vente' href="/conditions_generales">
          Conditions Générales de Vente
        </a>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;
