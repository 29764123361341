import React from "react";

function Title() {
  return (
    <div>
      <div
        id="choiceContact_section"
        className="h-full flex flex-col lg:py-[5rem] py-[2.5rem] lg:px-[6.25rem] px-[1rem] items-center background_contactMe bg-cover">
        <div className="flex flex-col justify-center items-center gap-2 max-w-screen-md lg:my-[5rem] lg:mx-[6.25rem]">
          <h1 className="text-oceanH1 font-yesevaOne text-4xl md:text-6xl font-normal leading-10 tracking-normal text-center pb-[0.5rem]">
            Joy Introspection
          </h1>
          <h2 className="text-[#01385FB2] text-lato text-xl text-center md:text-2xl font-extralight mb-[1rem]">
            Découvrons le métier fait pour vous en révélant votre plein potentiel
          </h2>
          <div className="flex">
          <h3 className="mr-2 py-1 px-3 max-content bg-[#09929d] text-white rounded-2xl text-center inline-flex items-start text-sm md:text-base">
            En visio et/ou en réel
          </h3>
          <h3 className="py-1 px-3 max-content bg-[#ED8B26] text-white rounded-2xl text-center inline-flex items-start text-sm md:text-base">
            3 mois
          </h3>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Title;
