import React from "react";
import mailIcon from "../../medias/mail_icon_contactMe.svg";
import phoneIcon from "../../medias/phone_icon_contactMe.svg";
import calendarIcon from "../../medias/calendar_icon_contactMe.svg";
import imageContactMe from "../../medias/margotbatoufflet_picture_contactMe.svg";

function ContactSection() {
  const contactOptions = [
    {
      title: "Envoyer un mail",
      text: "Vous pouvez m'envoyer un message à ",
      detail: "joyblast.coaching@gmail.com",
      icon: mailIcon,
      url: "mailto:joyblast.coaching@gmail.com",
    },
    {
      title: "Téléphoner",
      text: "Vous pouvez m’appeler directement par téléphone au ",
      detail: "06 25 46 04 02",
      icon: phoneIcon,
      url: "tel:+33625460402",
    },
    {
      title: "Prendre rendez-vous",
      text: "Tu veux prendre un premier rendez-vous avec moi ? Ça se passe par ",
      detail: "ici",
      icon: calendarIcon,
      url: "https://calendly.com/joyblast-coaching/30min?month=2024-01",
    },
  ];

  return (
    <div
      id="contact_section"
      className="w-full min-h-[60vh] flex items-center background_contactMe bg-no-repeat bg-cover mt-[4rem] md:mt-[0rem]"
    >
      <div className="flex flex-col md:flex-row w-full h-auto">
        <div id="image_section_contactMe" className="hidden md:block md:w-[50%]">
          <img src={imageContactMe} alt="margot_batoufflet_picture" className="md:h-full md:w-full md:object-cover" />
        </div>

        <div className="flex flex-col justify-center gap-2 w-full md:w-[50%] py-[2rem] px-[2rem] lg:pr-[6.25rem] md:pl-[2rem]">
          <h4 className="md:block text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal text-center lg:pb-[1rem] py-[1rem]">
            Contact
          </h4>
          <h5 className="md:block text-[#01385FB2] text-center text-lato text-2xl font-extralight pb-[1rem]">
            Envie de me rencontrer, ou me poser une question ?
          </h5>
          {contactOptions.map((option, index) => (
            <section
              key={index}
              id="contact_option"
              className="items-start bg-white rounded-lg drop-shadow-2xl px-[1.5rem] mb-[1.5rem] w-full"
            >
              <div className="flex align-center items-center mt-[1.5rem] ">
                <img src={option.icon} alt={option.title} className="mr-[1rem] max-w-[100%]" />
                <h3 className="text-oceanH1 font-lato font-black text-[24px] leading-10 tracking-normal">
                  {option.title}
                </h3>
              </div>
              <p className="w-full font-lato text-[#4D748f] mt-[0.5rem] mb-[1.5rem] pl-[3rem]">
                {option.text}
                <a href={option.url} className="text-dark hover:underline">{option.detail}</a>.
              </p>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
