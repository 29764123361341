import React, { useState } from "react";
import { Link } from "react-router-dom";

function Question() {
  const questions = [
    {
      id: 1,
      question:
        "Sur une échelle de 1 à 10, comment évalues-tu ton niveau actuel de satisfaction à l'égard de ta vie professionnelle ?",
      options: [
        { id: "A", option: "1 - Pas du tout satisfait" },
        { id: "B", option: "2" },
        { id: "C", option: "3" },
        { id: "D", option: "4" },
        { id: "E", option: "5" },
        { id: "F", option: "6" },
        { id: "G", option: "7" },
        { id: "H", option: "8" },
        { id: "I", option: "9" },
        { id: "J", option: "10 - Très satisfait" },
      ],
    },
    // Autres questions...
  ];

  const [errorMessage, setErrorMessage] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answers, setAnswers] = useState({});
  const [quizCompleted, setQuizCompleted] = useState(false);

  const handleNextQuestion = () => {
    // Vérification que la réponse actuelle est remplie
    if (answers[currentQuestion]?.length > 0) {
      setCurrentQuestion(currentQuestion + 1);
      setErrorMessage(""); // Réinitialisation du message d'erreur
    } else {
      setErrorMessage("Merci de répondre à la question.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleAnswer = (optionId, inputValue = "") => {
    // Mise à jour des réponses pour les questions à choix multiple
    const updatedAnswers = {
      ...answers,
      [currentQuestion]: inputValue !== "" ? [inputValue] : [optionId],
    };
    setAnswers(updatedAnswers);
  };

  const handleFinishQuiz = async () => {
    const allQuestionsAnswered =
      Object.keys(answers).length === questions.length;
    if (allQuestionsAnswered) {
      setQuizCompleted(true);
      // Enregistrement des réponses ici (si nécessaire)
    } else {
      setErrorMessage("Veuillez répondre à toutes les questions.");
    }
  };

  const renderQuestionOptions = () => {
    const question = questions[currentQuestion - 1];

    return question.type === "input" ? (
      <input
        type="text"
        value={answers[question.id]?.[0] || ""}
        className="w-fit bg-camellight py-4 px-4 rounded-xl my-4"
        onChange={(e) => handleAnswer(question.options[0].id, e.target.value)}
      />
    ) : (
      <ul className="flex flex-col justify-center w-fit h-full">
        {question.options.map((option) => (
          <li className="p-2 bg-camel h-full my-2 text-oceanH2" key={option.id}>
            <label>
              <input
                type="checkbox"
                value={option.id}
                checked={answers[currentQuestion]?.includes(option.id)}
                onChange={() => handleAnswer(option.id)}
              />
              {option.option}
            </label>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="h-content w-full flex flex-col justify-center items-center align-center">
      <div className="flex flex-col align-center items-center justify-center transition-opacity p-[2.5rem]">
        {quizCompleted ? (
          <div className="w-[1/2] text-center flex flex-col items-center align-center justify-center bg-white border-blue-900 border-solid">
            {/* Contenu post-quiz */}
          </div>
        ) : (
          <>
            <p className="text-green-blue font-lato font-normal text-base text-left">
              Question {currentQuestion}/{questions.length}
            </p>
            <h2 className="text-oceanH1 font-lato font-extrabold text-2xl my-2">
              {questions[currentQuestion - 1].question}
            </h2>
            {renderQuestionOptions()}
            <div className="flex justify-end pt-4">
              {currentQuestion > 1 && (
                <button
                  className="flex items-center gap-2 border-2 border-solid border-green-blue p-3 rounded mr-4 text-green-blue transition-opacity"
                  onClick={handlePreviousQuestion}
                >
                  Précédent
                </button>
              )}
              {currentQuestion < questions.length ? (
                <button
                  className="flex items-center gap-2 bg-green-blue text-white p-3 rounded"
                  onClick={handleNextQuestion}
                >
                  Suivant
                </button>
              ) : (
                <Link to="/prise_de_rendez-vous">
                  <button
                    className="flex items-center gap-2 bg-green-blue text-white p-3 rounded"
                    onClick={handleFinishQuiz}
                  >
                    Terminer le quiz
                  </button>
                </Link>
              )}
            </div>
            <p className="text-red-500 mt-4">{errorMessage}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default Question;
