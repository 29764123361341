import React from "react";
import MargotDescription from "../../medias/margot_batoufflet.png";

function Description() {
  const description = [
    {
      imageUrl: MargotDescription,
      title: "Salut, moi c'est Margot",
      text: (
        <>
          <p>
            En tant que designer produit, j'ai développé ces dernières années
            une passion pour la compréhension des besoins et aspirations des
            individus. À tel point que j'ai réalisé que mes compétences en
            communication, en empathie et en résolution de problèmes pouvaient
            alors aller au-delà de la création d'interfaces ; l’opportunité de
            faire une différence significative dans la vie des personnes.
          </p>
          <br />
          <p>
            J'ai alors décidé de devenir coach pour Multipotentiels et
            entrepreneuse. Rien ne me motive davantage que d'accompagner les
            individus aux multiples facettes à s’accomplir, se transformer, même
            s'ils doivent parfois aller à contre-courant, pour qu'ils puissent
            finalement construire une vie et une carrière en parfaite harmonie
            avec leur identité profonde.
          </p>
        </>
      ),
      button: {
        text: "En savoir plus",
        url: "/a_propos", 
      },
    },
  ];

  return (
    <div>
      {description.map((description, index) => (
        <div
          key={index}
          id="description_margot"
          className="w-full md:py-[5rem] bg-white bg-no-repeat flex flex-col items-center lg:flex-row lg:p-0 "
        >
          <div
            id="picture_section"
            className="w-full lg:w-[60%] flex md:justify-center"
          >
            <img
              alt="" // Laisser vide si l'image est décorative
              src={description.imageUrl}
              className="w-cover bg-cover"
            />
          </div>
          <div
            id="text_description"
            className="w-full lg:w-[40%] lg:pr-[6rem] text-left lg:mx-5 flex flex-col justify-center"
          >
            <h4 className="py-4 md:pt-[5rem] md:px-0 px-[1rem] text-oceanH1 text-3xl font-yesevaOne font-600 lg:text-[40px] md:text-4xl">
              {description.title}
            </h4>
            <div className="px-[1rem] md:px-0 lg:w-[100%] lg:pt-8 leading-7 text-oceanH2">
              {description.text}
            </div>
            <div className="flex justify-center lg:justify-start lg:pb-[5rem] pt-[2rem] pb-[2.5rem]">
              <a
                href='/a_propos'
                className="p-[1rem] h-10 bg-dark hover:bg-[#003D7F] text-white rounded text-base font-bold font-sans flex justify-center items-center"
              >
                En savoir plus
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Description;
