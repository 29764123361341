import React from "react";


import ContactSection from "./ContactSection";

function ContactMe() {
  return (
    <div className="main_section">
      <ContactSection />
    </div>
  );
}

export default ContactMe;
