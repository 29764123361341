import React from "react";
import ValidateIcon from "../../medias/icon_validate_goals.svg";

function Goals() {
  const goalscontent = [
    {
      title: "Objectifs",
      goals: [
        "Apprendre à vous reconnecter avec vous-même pour y voir plus clair",
        "Identifier et lâcher vos croyances limitantes",
        "Faire un bilan sur qui vous êtes vraiment",
        "Vous entourez de personnes qui vont vous stimuler",
        "Découvrir votre métier idéal et avoir un plan clair pour l’atteindre",
      ],
    },
    {
      textbutton: "Je rejoins la formation",
      link: "/questions",
    },
  ];

  return (
    <div className="flex flex-col align-center items-center justify-center bg-camel w-full px-[1rem] md:px-[5rem] py-[2.5rem] md:py-[5rem]">
      <h1 className="text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal text-center pb-[2rem]">
        Objectifs
      </h1>

      {goalscontent.map((section, index) => (
        <section
          className="w-full pb-[0.5rem] flex items-center justify-center"
          key={index}
        >
         {section.goals && (
  <ul>
    {section.goals.map((goal, goalIndex) => (
      <li
        key={goalIndex}
        className={`flex text-[#01385FB2] text-[1rem] font-lato font-extralight items-center ${
          goalIndex !== section.goals.length - 1 ? 'pb-4' : '' // Ajout de la condition pour le dernier élément
        }`}
      >
        <img
          src={ValidateIcon}
          alt="icon_validate_goals"
          className="pr-[0.5rem] max-w-full"
        />
        {goal}
      </li>
    ))}
  </ul>
)}
          {/* {section.textbutton && (
            <a
              href={section.link}
              className="bg-oceanH1 text-white mt-[1rem] p-[0.50rem] rounded-[0.25rem]"
            >
              {section.textbutton}
            </a>
          )} */}
        </section>
      ))}
    </div>
  );
}

export default Goals;
