import React from "react";
import illustration from "../../medias/banner_about.png";
import photoMargot from "../../medias/banner_margot_about_mobile.png";

function Banner() {
  return (
    <div>
      {/* Banner section start */}
      <div className="w-full md:h-[88vh] bg-white flex flex-col md:items-center md:justify-center pt-[9vh]"> {/* Ajout du padding-top pour compenser la hauteur du header */}
        <div
          id="main_section"
          className="w-full md:w-3/5 font-casual flex flex-col justify-center items-center content-center"
        >
          <h1 className="text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal md:pt-[0rem] py-[2rem] ">
            Moi c'est Margot
          </h1>
        </div>

        <div className="w-full md:w-3/5 px-[1rem] flex flex-col items-center justify-center">
          {/* Image pour desktop */}
          <img
            src={illustration}
            alt="illustration"
            className="hidden md:inline w-full object-cover"
          />
          {/* Image pour mobile */}
          <img
            src={photoMargot}
            alt="illustration mobile"
            className="w-full pb-[2rem] h-auto min-w-full rounded-xl md:hidden"
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
