import React from "react";
import HeroImage from "../../medias/bilan_margot_banner.jpg"; // Remplacer par le bon chemin de votre image

const HeroSection = () => {
  return (
    <section className="relative mt-[70px] bg-[#fffbf8] md:pt-0 md:px-0 pt-24 px-8 md:pl-16 flex flex-col lg:flex-row items-center justify-between">

      <div className="lg:w-1/2">
        <h1
          className="text-[#003366]  font-bold"
          style={{
            fontFamily: "Poppins",
            fontWeight: 300,
            fontSize: "26px",
          }}
        >
          BILAN DE COMPÉTENCES
        </h1>
        <p
          className="text-lg lg:text-xl text-[#003366] leading-snug font-['Open_Sans']"
          style={{
            fontFamily: "Lato",
            fontSize: "24px",
            fontWeight: "300",
            lineHeight: "28.8px",
            textAlign: "left",
          }}
        >
          Transforme ta carrière en 8 semaines <br /> avec un accompagnement
          individuel sur-mesure{" "}
        </p>

        <button className="mt-6 bg-[#007C7B] text-white py-3 px-8 rounded-md hover:bg-[#005e5b] transition-colors font-lato">
          Je suis intéressé(e){" "}
        </button>
      </div>

      {/* Image à droite avec dégradé */}
      <div className="lg:w-1/2 lg:h- mt-8 lg:mt-0 relative flex justify-center lg:justify-end h-full">
        <div className="relative w-full h-full">
          <img
            src={HeroImage}
            alt="Hero"
            className="w-full h-full object-cover rounded-lg"
          />
          {/* Dégradé appliqué sur l'image */}
          <div className="absolute inset-0 bg-gradient-to-l from-transparent to-[#fffbf8]"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
