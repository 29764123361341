import React from "react";
import firstProgramImage from "../../../medias/succes.jpg";
import secondProgramImage from "../../../medias/travail_moderne.jpg";

function Articles() {


  const programme = [  { 
    title: "Multipotentiels : vous êtes un atout dans le monde du travail moderne",
    text: "Avez-vous mille passions, des intérêts qui varient presque autant que les saisons, et une soif insatiable d'apprendre...",
    imageUrl: secondProgramImage,
    button: "Découvrir l’article",
    link: '/vous_etes_un_atout_dans_le_monde_du_travail',
  },
    {
      title: "Multipotentiels : transformer votre polyvalence en succès entrepreneurial",
      text: "En tant que multipotentiels, avec des talents et intérêts multiples, vous devez souvent vous heurter à certains dilemmes professionnels...",
      imageUrl: firstProgramImage,
      button: "Découvrir l’article",
      link: '/transformer_votre_polyvalence_en_succes_entrepreneurial',
    },
  ];

  return (
    <div className="w-full bg-white md:mt-12 py-[5rem] flex flex-col items-center">
    {/* Titre dynamique */}
    <h3 className="text-oceanH1 font-yesevaOne text-3xl md:text-4xl mb-8">
      Vous aimerez également ...
    </h3>
    
    {/* Flex layout pour centrer les cartes */}
    <div className="flex flex-col md:flex-row justify-center gap-6 max-w-4xl px-6">
      {programme.map((article, index) => (
        <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden min-w-[25rem] max-w-[25rem]">
          <img
            src={article.imageUrl}
            alt=""
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h4 className="font-lato text-oceanH1 text-xl font-bold mb-4">
              {article.title}
            </h4>
            <div className="w-12 border-b-4 border-turquoise mb-4"></div>
            <p className="text-darkBlue text-base mb-6">
              {article.text}
            </p>
            <a
              href={article.link}
              className="inline-block text-dark border-2 border-dark py-2 px-4 rounded-md font-bold hover:bg-dark hover:text-white transition-colors"
            >
              {article.button}
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
}

export default Articles;
