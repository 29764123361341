import React from "react";
import couverture from "../../../medias/firstarticle_couverture.png";

// Composant réutilisable pour chaque section
const Section = ({ title, content, isList = false }) => {
  return (
    <section className="flex flex-col py-2">
      <div className="">
        <h2 className="text-2xl md:text-3xl pb-4 lg:text-4xl font-bold text-[#1E2A39] font-yesevaOne">
          {title}
        </h2>
        {isList ? (
          <ul className="list-disc ml-6 text-oceanH2 leading-7 text-justify md:pb-8 text-base md:text-l">
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <p className="text-oceanH2 leading-7 text-justify text-base md:pb-8 md:text-l">
            {content}
          </p>
        )}
      </div>
    </section>
  );
};

const Content = () => {
  // Mise à jour avec l'article complet

  const introduction = `En tant que multipotentiels, avec des talents et intérêts multiples, vous devez souvent vous heurter à certains dilemmes professionnels. Comment choisir une seule voie quand on est un véritable couteau suisse humain ? Comment bâtir une carrière satisfaisante sans se sentir contraint dans une seule discipline ? Si vous vous reconnaissez dans cette description, l’entrepreneuriat pourrait bien être la solution idéale. En tant qu’entrepreneur, vous avez carte blanche pour exploiter tout ce qui fait de vous un être unique et créatif.`;

  const section1 = `Un job standard, où on fait toujours la même chose et aux contours rigides, peut-être un vrai enfer pour un multipotentiel. Alors pourquoi ne pas créer son propre emploi sur mesure où vous pouvez tout faire, à votre manière ? Redessiner votre parcours comme bon vous semble ? C’est là que l’entrepreneuriat peut entrer en scène !`;

  const avantages = [
    "Liberté totale : vous décidez de vos projets, de vos horaires et de vos missions. Si demain vous avez envie d’ajouter une nouvelle corde à votre arc, go ! Il n’y a aucune limite.",
    "Polyvalence encouragée : Vous pouvez enfin fusionner vos compétences et centres d’intérêt. Par exemple, un jour vous créez du contenu, le lendemain vous développez une stratégie marketing, et la semaine suivante vous lancez un produit digital.",
    "Business évolutif : votre entreprise peut grandir et évoluer en même temps que vous. Vous êtes passionné aujourd’hui par le coaching, et demain par l’écriture de livres ? Pas de souci, votre business peut suivre le mouvement.",
  ];

  const strategie = `C’est important de garder à l’esprit que vous devez construire une aventure entrepreneuriale qui vous ressemble, qui colle à votre personnalité et vos multiples talents. Sinon, vous risquez de vous emprisonner dans de nouvelles responsabilités qui ne vous correspondent pas.`;

  const strategiePoints = [
    "Définissez une vision globale et flexible : Au lieu de vous restreindre à un seul domaine, créez une mission globale qui puisse évoluer au fil de vos passions.",
    "Choisissez un modèle d'affaires flexible : Construisez un projet à la carte avec différents types de services ou produits qui vous permettent de passer d’un projet à l’autre selon vos envies.",
    "Créez une marque personnelle qui reflète votre polyvalence : Soyez transparent sur votre diversité avec votre audience et montrez comment elle peut apporter une réelle valeur ajoutée.",
  ];

  const defis = [
    "Dispersion : c’est tentant de tout faire à la fois, mais apprenez à prioriser.",
    "Perfectionnisme : lâchez prise. Mieux vaut fait que parfait.",
    "Syndrome de l’imposteur : Acceptez que vous ne maîtriserez peut-être pas chaque domaine à 100%, mais votre vision d'ensemble et vos capacités d'adaptation sont inestimables.",
    "Identité professionnelle cohérente : Construisez une image forte qui montre votre diversité, mais de manière claire et compréhensible pour votre audience.",
  ];

  const conclusion = `En tant que multipotentiel, vous avez de super-pouvoirs à exploiter et l'entrepreneuriat peut être l'endroit idéal pour les transformer en une carrière sur mesure, épanouissante, pleine de projets excitants et d'opportunités illimitées.`;

  return (
    <div className="w-full">
      {/* Header Section with background image */}
      <div className="relative h-[300px] md:h-[400px] lg:h-[500px] bg-gray-800">
        <img
          src={couverture}
          alt="Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-3xl text-center md:text-5xl lg:text-6xl font-bold text-white font-yesevaOne">
            Multipotentiels : transformez votre polyvalence en succès
            entrepreneurial{" "}
          </h1>
        </div>
      </div>

      {/* Content Sections */}
      <div className="md:px-[20%] md:py-[4rem] px-[10%] py-[2rem] ">
        <Section title="Introduction" content={introduction} />
        <Section
          title="Pourquoi l’entrepreneuriat est fait pour les multipotentiels ?"
          content={section1}
        />
        <Section
          title="Les avantages de l'entrepreneuriat"
          content={avantages}
          isList={true}
        />

        {/* GIF intégré */}
        <div className="my-6 flex justify-center">
          <img
            src="https://media.giphy.com/media/Wy9HG0SmAlwhUOTrmb/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>

        <Section
          title="Stratégies pour transformer votre polyvalence en succès entrepreneurial"
          content={strategie}
        />
        <Section
          title="Stratégies clés"
          content={strategiePoints}
          isList={true}
        />
        <Section
          title="Défis spécifiques aux multipotentiels"
          content={defis}
          isList={true}
        />

        {/* Deuxième GIF intégré */}
        <div className="my-6 flex justify-center">
        <img
            src="https://media.giphy.com/media/kSNyWHaooEujI6kpqX/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>

        <Section title="Conclusion" content={conclusion} />
      </div>
    </div>
  );
};

export default Content;
