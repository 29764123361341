import communautePicture from "../../medias/communaute_picture.jpg";
import fichePicture from "../../medias/joy_community.jpg";
import checkArrow from "../../medias/check.svg";

const MultiAdventureComponent = () => {
  return (
    <div className="bg-white py-12 px-4 lg:px-20">
      {/* Première section */}

      <div className="flex flex-col lg:flex-row items-center lg:items-center mt-16 md:mt-0">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start mb-8 lg:mb-0">
          <img
            src={communautePicture}
            alt="Communauté multipotentiels"
            className="w-[80%] lg:w-auto"
          />
        </div>
        <div className="w-full lg:w-1/2 pl-0 lg:pl-12">
          <h2 className="text-[#00205b] text-[30px] font-yesevaOne font-bold mb-6">
            Une communauté d’autres Multipotentiels
          </h2>
          <p className="text-[#00205b] text-[18px] font-bold leading-[28px] mb-6">
            Pour trouver l’inspiration, vous motiver et vous soutenir
            mutuellement
          </p>
          <ul className="text-[#00205b] text-[18px] font-extralight leading-[28px] space-y-4">
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6 mt-1" />
              <span>
                <strong className="font-bold">
                  Avoir un soutien entre pairs
                </strong>{" "}
                : bénéficiez d’un environnement collaboratif où le soutien entre
                pairs favorise l’entraide et l’échange d’expériences
                enrichissantes entre multipotentiels.
              </span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6 mt-1" />
              <span>
                <strong className="font-bold">
                  Échanger de bonnes pratiques et des ressources
                </strong>{" "}
                : pour optimiser votre apprentissage, vos efforts, éviter les
                erreurs courantes et enfin favoriser la croissance collective.
              </span>
            </li>
            <li className="flex items-start">
              <img src={checkArrow} alt="check" className="mr-6 mt-1" />
              <span>
                <strong className="font-bold">Stimuler la créativité</strong> :
                la diversité des profils au sein de la communauté permet de
                booster votre créativité et favoriser l’innovation, en offrant
                de nouvelles perspectives et approches.
              </span>
            </li>
            <li className="flex items-start">
              <img src={checkArrow} alt="check" className="mr-6 mt-1" />
              <span>
                <strong className="font-bold">Garder la motivation</strong> :
                bénéficiez d’un environnement motivant où les membres
                s’encouragent mutuellement à atteindre leurs objectifs.
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Deuxième section */}

      <div className="flex flex-col lg:flex-row items-center lg:mt-8  lg:items-start">
        <div className="w-full lg:w-1/2 pr-0 lg:pr-12">
          <h2 className="text-[#00205b] font-yesevaOne text-[30px] font-bold my-8 md:mb-6">
            Le Joy Community c'est ...{" "}
          </h2>
          <p className="text-[#00205b] text-[18px] font-bold leading-[28px] mb-6">
            Une communauté en ligne avec ...
          </p>
          <ul className="text-[#00205b] text-[18px] font-extralight leading-[28px] space-y-4 mb-6">
            <li className="flex items-center ">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">Un espace de discussion : </strong>
                pour discuter avec la communauté et que je puisse répondre à vos
                questions.
              </span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">4 ateliers en ligne : </strong> 
                pour discuter avec la communauté et que je puisse répondre à vos
                questions.
              </span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">Des rencontres : </strong>  chaque
                personne de la communauté peut, à son initiative, organiser des
                rencontres avec les autres, que ce soit à distance ou en
                présentiel{" "}
              </span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold"> Des supports : </strong>
                des ressources et des outils que vous pourrez utiliser à vie à
                chacune de vos transitions.
              </span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">
                  {" "}
                  Des exercices pratiques :
                </strong>
                des activités et des exercices concrets à faire en groupe.{" "}
              </span>{" "}
            </li>

            
          </ul>
      
          <a href='#join' className="mt-6 bg-[#09929d] text-white py-3 px-8 rounded-md hover:bg-[#005e5b] transition-colors font-lato">
          Je suis prêt(e) à vous rejoindre !   </a>
      

          
        </div>
        <div className="w-full lg:w-1/2 mt-12 lg:mt-0 flex justify-center lg:justify-center">
          <img
            src={fichePicture}
            alt="Supports de cours"
            className="w-[80%] lg:w-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default MultiAdventureComponent;
