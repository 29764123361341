import React from "react";
import HeroImage from "../../medias/banner_detail_formation.png"; // Remplacer par le bon chemin de votre image

const HeroSection = () => {
  return (
    <section className="relative bg-[#feefdf] md:pt-0 md:px-0 pt-24 px-8 md:pl-16 flex flex-col lg:flex-row items-center justify-between">
      {/* Contenu texte à gauche */}
      <div className="lg:w-1/2 ">
        <h1
          className="text-[#003366]  md:pt-20 font-bold"
          style={{
            fontFamily: "Permanent Marker, sans-serif",
            fontWeight: 400,
            fontSize: "32px",
            lineHeight: "50px",
          }}
        >
          Multi Adventure
        </h1>
        <p
          className="text-lg lg:text-xl text-[#003366] leading-snug font-['Open_Sans']"
          style={{
            fontFamily: "Lato",
            fontSize: "24px",
            fontWeight: "300",
            lineHeight: "28.8px",
            textAlign: "left",
          }}
        >
          Explore et exploite tes multiples talents pour transformer ta vie
        </p>
        <p className="text-base lg:text-lg text-[#003366] font-medium font-lato">
          Rejoins la liste d'attente et obtiens des bonus gratuits pour changer
          de carrière
        </p>
        <button className="mt-6 bg-[#007C7B] text-white py-3 px-8 rounded-md hover:bg-[#005e5b] transition-colors font-lato">
          Rejoindre la liste d’attente
        </button>
      </div>

      {/* Image à droite avec dégradé */}
      <div className="lg:w-1/2 mt-8 lg:mt-0 relative flex justify-center lg:justify-end h-full">
        <div className="relative w-full h-full">
          <img
            src={HeroImage}
            alt="Hero"
            className="w-full hidden md:block pt-20 h-full object-cover rounded-lg"
          />
          {/* Dégradé appliqué sur l'image */}
          <div className="absolute inset-0 bg-gradient-to-l from-transparent to-[#feefdf]"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
