import React from "react";
import couverture from "../../../medias/couverture_articles.png";

// Composant réutilisable pour chaque section
const Section = ({ title, content, isList = false }) => {
  return (
    <section className="flex flex-col py-2">
      <div className="">
        <h2 className="text-2xl md:text-3xl pb-4 lg:text-4xl font-bold text-[#1E2A39] font-yesevaOne">
          {title}
        </h2>
        {isList ? (
          <ul className="list-disc ml-6 text-oceanH2 leading-7 text-justify md:pb-8 text-base md:text-l">
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <p className="text-oceanH2 leading-7 text-justify text-base md:pb-8 md:text-l">
            {content}
          </p>
        )}
      </div>
    </section>
  );
};

const Content = () => {
  // Texte de l'article
  const introduction = `Vous êtes du genre à jongler entre une multitude de centres d’intérêt, à sauter d’une passion à l’autre avec enthousiasme, pour ensuite ressentir de l’ennui dès que vous êtes trop longtemps concentré sur une seule chose ? Si c'est le cas, bienvenue dans la grande famille des multipotentiels. C’est un mot un peu savant, de plus en plus populaire, pour dire que vous avez de multiples talents et intérêts. Mais voilà, dans notre monde qui adore la spécialisation et les parcours bien tracés, les multipotentiels sont confrontés à un vrai casse-tête : comment équilibrer toutes ces passions tout en construisant une carrière stable ? 

  Dans cet article, je vous dévoile les stratégies pour faire cohabiter votre vie professionnelle avec vos aspirations variées, votre curiosité débordante et, bien sûr, votre bien-être.`;

  const multipotentielDefinition = `Être multipotentiel est par définition être quelqu'un qui possède de multiples intérêts, passions, compétences, et qui ne se contente pas de se spécialiser dans un seul domaine. Contrairement à un spécialiste qui se concentre profondément sur une seule expertise, un multipotentiel s’épanouit tout simplement dans la diversité et dans des domaines relativement différents, ce qui rend souvent difficile pour elle de se concentrer sur une seule carrière ou passion.`;

  const recognitionList = [
    "Curiosité insatiable : les personnes multipotentielles se distinguent par leur curiosité insatiable. Elles aiment explorer, apprendre et maîtriser de nouveaux domaines rapidement.",
    "Multi-facettes : grâce à cette curiosité, les multipotentiels ont la particularité de ne pas vouloir se limiter à une seule passion ou une seule carrière.",
    "Pensée en arborescence : cette capacité à toucher à tout leur permet aussi souvent de faire des associations riches entre des idées qui semblent sans lien.",
    "Recommencer de zéro : un multipotentiel s’intéresse à un sujet à fond, devient expert, finit par s’ennuyer pour laisser tomber et s’intéresser à un sujet totalement différent.",
    "Adaptabilité : pour finir, ces personnes ont plus de facilité à s’adapter à des nouveaux contextes grâce à leurs transitions, leur curiosité insatiable et leur pensée en arborescence.",
  ];

  const challengesList = [
    "Dispersion : Évitez de vous éparpiller, apprenez à prioriser.",
    "Perfectionnisme : Lâchez prise, mieux vaut fait que parfait.",
    "Syndrome de l’imposteur : Acceptez que vous ne maîtriserez pas tout à 100%, mais vos capacités d'adaptation sont inestimables.",
    "Identité professionnelle cohérente : Construisez une image claire et compréhensible pour votre audience.",
  ];

  const strategiesForMultipotential = `Les multipotentiels peuvent explorer la polyvalence dans leur travail en choisissant des métiers qui valorisent leurs compétences diversifiées, comme le marketing digital ou la gestion de projet. Le jobcrafting, l’entrepreneuriat, et l'intrapreneuriat sont également des voies prometteuses pour permettre à ces profils d'explorer plusieurs aspects de leur travail.`;

  const portfolioWork = `Le travail en portfolio est idéal pour les multipotentiels qui aiment gérer plusieurs projets simultanément. Il permet de combiner plusieurs emplois ou activités (freelancing, consulting), offrant ainsi la possibilité d'explorer plusieurs passions tout en diversifiant ses sources de revenus.`;

  const arborescenceCareer = `Une carrière en arborescence se développe comme un arbre : chaque branche représente un nouveau domaine ou projet qui s'ajoute à votre parcours. Cette approche permet de toucher à différents secteurs tout en créant des connexions entre eux, et vous offre la possibilité d’évoluer sans vous enfermer dans une seule spécialité.`;

  const balancePassionCareer = `Trouver l'équilibre entre passions et carrière signifie organiser vos passions en fonction des différentes phases de votre vie. Cela peut impliquer de faire des rotations entre différents projets, tout en conservant certaines passions comme simples loisirs pour éviter le burnout et préserver le plaisir.`;

  const conclusion = `Être multipotentiel, c’est naviguer entre plusieurs mondes, passions, projets et ambitions. Plutôt que de voir cela comme un obstacle, il est possible de transformer cette diversité en une force. Embrassez cette pluralité, car elle est une véritable richesse pour votre carrière et votre bien-être personnel !`;

  return (
    <div className="w-full">
      {/* Header Section with background image */}
      <div className="relative h-[300px] md:h-[400px] lg:h-[500px] bg-gray-800">
        <img
          src={couverture}
          alt="Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-3xl text-center md:text-5xl lg:text-6xl font-bold text-white font-yesevaOne">
            Multipotentiels : comment équilibrer vos passions et votre carrière
            ?
          </h1>
        </div>
      </div>

      {/* Content Sections */}
      <div className="md:px-[20%] md:py-[4rem] px-[10%] py-[2rem] ">
        <Section title="Introduction" content={introduction} />
        <Section
          title="Comprendre les profils multipotentiels"
          content={multipotentielDefinition}
        />

        <Section
          title="Comment reconnaître une personne multipotentielle"
          content={recognitionList}
          isList={true}
        />

        <Section
          title="Les défis spécifiques d’être multi-casquettes"
          content={challengesList}
          isList={true}
        />

        {/* GIF intégré */}
        <div className="my-6 flex justify-center">
          <img
            src="https://media.giphy.com/media/FSKkEIpSsN0q2NVa1m/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>

        <Section
          title="Stratégies pour concilier passions et carrières"
          content={strategiesForMultipotential}
        />

        <Section
          title="Adopter un travail en portfolio"
          content={portfolioWork}
        />

        <Section
          title="Construire une carrière en arborescence"
          content={arborescenceCareer}
        />

        <Section
          title="Trouver l’équilibre entre passions personnelles et professionnelles"
          content={balancePassionCareer}
        />

        <Section title="Conclusion" content={conclusion} />

        {/* Deuxième GIF intégré */}
        <div className="my-6 flex justify-center">
          <img
            src="https://media.giphy.com/media/XEo7frzKn912BPfSmf/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>
      </div>
    </div>
  );
};

export default Content;
