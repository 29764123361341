import React from "react";
import checkIcon from "../../medias/check.svg"; 

// Liste d'objectifs
const objectifs = [
  "Apprendre à vous reconnectez avec vous-même pour y voir plus clair",
  "Faire un bilan sur qui vous êtes vraiment et ce qui fait votre force",
  "Identifiez et lâchez vos croyances limitantes",
  "Vous entourez de personnes qui vont vous stimuler",
  "Découvrir votre prochaine trajectoire professionnelle et avoir un plan concret"
];

const Objectif = () => {
  return (
    <section className="py-16 px-4 lg:px-20 bg-[#fcead9]">
      <div className="text-center">
        <h2 className="text-[#00205b] font-yesevaOne text-[30px] font-bold mb-8">
          Objectifs
        </h2>
      </div>
      <ul className="max-w-2xl mx-auto space-y-8">
        {objectifs.map((objectif, index) => (
          <li key={index} className="flex items-center space-x-4">
            <img
              src={checkIcon}
              alt="Check icon"
            />
            <p className="text-[#4d6f85] text-[18px] font-lato font-light">
              {objectif}
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Objectif;
