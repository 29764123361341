const Testimonial = () => {
    return (
      <div className="bg-[#002955] py-20 px-8 text-center">
        <p
          className="text-white font-lato text-[18px] font-light leading-[28px] text-center max-w-4xl mx-auto"
          style={{ fontWeight: 300 }}
        >
          "Grâce à cette formation, j'ai pu élaborer un plan d'action concret pour atteindre mes objectifs, tout en trouvant un équilibre pour mes passions entre ma vie personnelle et professionnelle. La partie sur la multipotentialité et l'identification de ce qui me rend unique m'ont permis de mieux comprendre qui je suis et comment je fonctionne. Puis, les techniques pour surmonter mes peurs ont été révélatrices !"
        </p>
        <p className="mt-6 text-[#00cccc] font-lato text-[18px] leading-[28px] font-semibold text-center">
          Maximilien - Développeur Web
        </p>
      </div>
    );
  };
  
  export default Testimonial;
  