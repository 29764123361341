import React from "react";
// import Button from "../MiniComponents.js/Button";

function AccordionItem({ title, text, text2, text3 }) {
  return (
    <div className="lg:py-[5rem] lg:px-[6.25rem] py-8 px-4 bg-background_description bg-[#feefdf] bg-cover flex flex-col items-center">
      <div className="w-full lg:w-3/4 lg:text-left lg:px-[6rem] flex flex-col justify-center text-center">
        <h4 className="min-w-full text-oceanH1 text-center font-yesevaOne text-4xl font-normal leading-10 tracking-normal pb-8 lg:pb-6">
          {title}
        </h4>
        <p className="text-oceanH2 text-left leading-7 text-base md:text-l mb-4">
          {text}
        </p>
        <br />
        <p className="text-oceanH2 text-left leading-7 text-base md:text-l mb-4">
          {text2}
        </p>
        <br />
        <p className="text-oceanH2 text-left leading-7 text-base md:text-l mb-4">
          {text3}
        </p>
        
        {/* <Button
          text="En savoir plus"
          url=""
          className="mt-4"
        /> */}
      </div>
    </div>
  );
}

function Description() {
  const descriptions = [
    {
      title:
        "Pourquoi je suis coach pour multipotentiels ?",
      text: "J’aime casser les codes quand il s’agit de mener ma vie alignée avec mes valeurs. Quand on me dit “ce n’est pas possible!”, je perçois en réalité un défi à relever et je me dis simplement qu’il faut que je trouve la bonne porte à ouvrir pour atteindre mon objectif.",
      text2:
        "En tant designer produit, j'ai développé ces dernières années une passion pour la compréhension des besoins et aspirations des individus. À tel point que j'ai réalisé que mes compétences en communication, en empathie et en résolution de problèmes pouvaient alors aller au-delà de la création d'interfaces ; l’opportunité de faire une différence significative dans la vie des personnes.",
      text3:
        "J'ai alors décidé de devenir coach pour Multipotentiels et entrepreneuse. Rien ne me motive davantage que d'accompagner les individus aux multiples facettes à s’accomplir, se transformer, même s'ils doivent parfois aller à contre-courant, pour qu'ils puissent finalement construire une vie et une carrière en parfaite harmonie avec leur identité profonde.",
    },
  ];

  return (
    <div>
      {descriptions.map((description, index) => (
        <AccordionItem key={index} {...description} />
      ))}
    </div>
  );
}

export default Description;
