import React from "react";
import certif from "../../medias/certification.png";

function Certification() {
  return (
    <div className="h-screen flex align-center justify-center bg-camel">
      <div className="lg:p-16 w-3/4 display flex align-center jusitfy-center items-center">
        <img
          className=" lg:w-full "
          alt="certication_accompagnement_transition_professionnelle"
          src={certif}
        />
      </div>
    </div>
  );
}

export default Certification;
