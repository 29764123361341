import { useState } from 'react';
import { Dialog, Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../medias/logo.svg';
import phone from '../medias/phone.svg';

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="fixed top-0 left-0 w-full font-lato shadow-md bg-white z-50">
        <nav className="flex items-center justify-center p-4 lg:px-8" aria-label="Global">
          {/* Menu burger à gauche (mobile) */}
          <div className="lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Logo centré */}
          <div className="flex justify-center flex-1 lg:flex-2">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Joy Blast</span>
              <img className="h-auto w-auto" src={logo} alt="Joy Blast" />
            </a>
          </div>

          {/* Icône téléphone à droite (mobile) */}
          <div className="lg:hidden">
            <a href="tel:+1234567890" className="inline-flex items-center justify-center text-gray-700">
              <img className="h-6 w-6" src={phone} alt="Phone" />
            </a>
          </div>

          {/* Menu pour desktop */}
          <Popover.Group className="hidden lg:ml-16 lg:font-lato lg:flex lg:gap-x-12">
            <a href="/mes_services" className="text-oceanH1 font-lato text-[16px] font-extrabold uppercase hover:text-[#09929D]">
              Mes services
            </a>
            <a href="/bonus" className="text-oceanH1 font-lato text-[16px] font-extrabold uppercase hover:text-[#09929D]">
              Bonus Gratuits
            </a>
            <a href="/blog" className="text-oceanH1 font-lato text-[16px] font-extrabold uppercase hover:text-[#09929D]">
              Blog
            </a>
            <a href="/a_propos" className="text-oceanH1 font-lato text-[16px] font-extrabold uppercase hover:text-[#09929D]">
              À propos
            </a>
          </Popover.Group>

          {/* Bouton de contact en desktop */}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/contactez-moi" className="lg:text-center lg:inline-flex lg:items-center justify-center lg:w-40 lg:h-10 bg-[#002955] hover:bg-[#003D7F] text-white rounded text-base font-bold font-sans">
              Me contacter
            </a>
          </div>
        </nav>

        {/* Menu mobile */}
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Joy Blast</span>
                <img className="h-8 w-auto" src={logo} alt="Logo" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6 mb-8">
                <a
                    href="/mes_services"
                    className="mt-8 uppercase -mx-3 block rounded-lg px-3 py-2 text-base font-lato leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Mes services
                  </a>
                  <a
                    href="/bonus"
                    className="uppercase -mx-3 block rounded-lg px-3 py-2 text-base font-lato leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Bonus Gratuits
                  </a>
                  <a
                    href="/blog"
                    className="mt-8 uppercase -mx-3 block rounded-lg px-3 py-2 text-base font-lato leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Blog
                  </a>
                
                  <a
                    href="/a_propos"
                    className="mb-8 uppercase -mx-3 block rounded-lg px-3 py-2 text-base font-lato leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    À propos
                  </a>
                  
                </div>
                <a
                  href="/contactez-moi"
                  className="mt-8 px-4 py-4 lg:text-center lg:inline-flex lg:items-center justify-center lg:w-40 lg:h-10 lg:mr-6 lg:ml-10 bg-[#002955] hover:bg-[#003D7F] text-white rounded text-base font-bold font-sans"
                >
                  Me contacter
                </a>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

    
    </>
  );
}

