import React from "react";

import Banner from "./Banner";
// import Program from "./Program";
// import Method from "./Method";
import Description from "./Description";
import Bonus from "./Bonus";
// import Opinion from "./Opinion";
// import ContactInfo from "../About/ContactForm";
import ContactBanner from "../ContactBanner";
import Service from "./Service";
import Articles from "./Articles";
import Avis from "./Avis";

function Home() {
  return (
    <div className="main_section">
      <Banner />
      <Service />
      <Bonus />
      {/* <Program /> */}
      {/* <Method /> */}
      <Articles />
      <Description />
      <Avis />

      {/* <Opinion /> */}
      <ContactBanner />
    </div>
  );
}

export default Home;
