import React from "react";

function Service() {
  const services = [
    {
      id: "1",
      title: "MULTI ADVENTURE",
      subtitle:
        "Explore & exploite tes multiples talents pour transformer ta vie",
      text: "Tu es multipotentiel(le) et tu as envie de transformer ta vie et ta carrière en combinant tes multiples passions et intérêts ? Cette formation en ligne en groupe est spécialement conçue pour exploiter ta richesse intérieure, travailler sur ta posture, tes peurs, croyances, ... dans le but final de développer les stratégies pour concrétiser l’avenir professionnel que tu désires.",
      button: "En savoir plus",
      link: "/multi_adventure",
      fontStyle: {
        fontFamily: "Permanent Marker, sans-serif",
        fontWeight: 400,
        fontSize: "32px",
        lineHeight: "50px",
      },
    },
    {
      id: "2",
      title: "BILAN DE COMPÉTENCES",
      subtitle:
        "Transforme ta carrière en 8 semaines avec un accompagnement sur-mesure",
      text: "Si tu es désireux(se) de mieux identifier et valoriser tes compétences uniques pour entamer une réorientation professionnelle, l’approche 100% personnalisée du bilan de compétences t’offre un accompagnement complet. Grâce à des entretiens individuels et des outils d'évaluation spécialisés, je t’aiderai à définir tes objectifs de carrière et à élaborer un plan d'action concret.",
      button: "En savoir plus",
      link: "/bilan_de_competences",
      fontStyle: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 300,
        fontSize: "26px",
        lineHeight: "50px",
      },
    },
    {
      id: "3",
      title: "JOY COMMUNITY",
      subtitle: "Communauté de partage & d’entraide entre Multipotentiels",
      text: "Découvre une communauté dynamique de Multipotentiels aux parcours diversifiés et inspirants. Rejoins-nous pour accéder à un espace de discussion en ligne et des lives collectifs, où tu pourras libérer tes idées, partager tes projets passionnants, booster ta motivation et obtenir du soutien pour atteindre de nouveaux sommets.",
      button: "En savoir plus",
      link: "/joy_community",
      fontStyle: {
        fontFamily: "Yeseva One, sans-serif",
        fontWeight: 400,
        fontSize: "26px",
        lineHeight: "50px",
      },
    },
  ];

  return (
    <div>
      <section
        id="service_section"
        className="relative w-full h-full bg-[#fdedde] md:bg-transparent md:bg-background_feuille md:bg-right-top md:bg-no-repeat z-40 flex flex-col items-center pb-4 md:pb-20"
      >
        <h3 className="text-oceanH1 font-yesevaOne  mt-8 md:mt-20 font-600 text-3xl md:text-4xl">
          Mes services
        </h3>

        <div
          id="services"
          className="relative w-full z-40 max-w-7xl mt-4 flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-4"
        >
          {services.map((service) => (
            <div
              key={service.id}
              className="w-full md:w-1/3 rounded-xl flex items-center md:py-4 m-0 py-0 md:items-center md:align-center"
            >
              {/* regler le soucis de marge sur la card */}
              <div
                className="w-full flex flex-col rounded-xl justify-between text-left p-8 bg-white h-full mx-4"
                style={{
                  minHeight: "450px", 
                  boxShadow: "0px 15px 40px rgba(0, 41, 85, 0.3)",
                }}
              >
                <h3 style={service.fontStyle} className="text-[#002d72] mb-2">
                  {service.title}
                </h3>
                <h4 className="text-oceanH2 font-lato text-lg md:text-xl mb-2">
                  {service.subtitle}
                </h4>
                <div
                  id="divider"
                  className="h-[3px] w-1/4 bg-[#09929D] my-4"
                ></div>
                <p className="text-oceanH2 leading-7 text-base md:text-l mb-4">
                  {service.text}
                </p>
                <div className="flex-grow"></div>{" "}
                {/* Cela permet de pousser le bouton vers le bas */}
                <a
                  href={service.link}
                  className="w-fit bg-oceanH1 text-white font-bold py-2 px-4 rounded hover:bg-oceanH2"
                >
                  {service.button}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Service;
