import React from "react";
// import step_picture from "../../medias/picture_step_left.svg";
// import step_picture2 from "../../medias/picture1_step_right.svg";
// import step_picture3 from "../../medias/picture2_step_right.svg";

// Composant principal avec la section "IMPORTANT" et le Stepper
const StepperWithImportantSection = () => {
  const steps = [
    {
      number: 1,
      title: "J’apprends à me reconnecter à moi-même",
      description:
        "Se reconnecter à soi-même pour trouver la clarté, la direction, et la source d'une vie épanouissante et authentique.",
    },
    {
      number: 2,
      title: "Je m’entoure",
      description:
        "Trouver des personnes qui vont me soutenir pour bénéficier de conseils, d'encouragements et d'expertise pour augmenter mes chances de succès dans ma transition.",
    },
    {
      number: 3,
      title: "Je lâche mes idées reçues",
      description:
        "Apprendre à identifier et à lâcher les croyances inconscientes qui te freinent au quotidien.",
    },
    {
      number: 4,
      title: "Mais qui suis-je ?",
      description:
        "Découvrir l'essence de ce qui te met en mouvement, te donne envie, comble tes besoins, tes forces, tes faiblesses, ta zone de génie, tes passions ... . Si tu sais qui tu es vraiment, tu pourras trouver mieux définir ton avenir.",
    },
    {
      number: 5,
      title: "J’imagine mon avenir",
      description:
        "Identifier ensemble plusieurs pistes professionnelles compatibles avec ta personnalité et tes aspirations.",
    },
    {
      number: 6,
      title: "Je passe à l’action",
      description:
        "Découvrir différentes pistes de métiers compatibles via des immersions, entretiens, évènements, ...",
    },
    {
      number: 7,
      title: "Je monte mon plan d’action",
      description:
        "Faire le point sur ce qui compte vraiment pour définir des objectifs et monter ensemble un plan d’action.",
    },
  ];

  return (
    <div className=" flex flex-col justify-center lg:bg-background_step bg-cover bg-no-repeat align-center items-center py-[4rem] bg-white relative">
 

      {/* Titre du Stepper */}
      <h1 className="text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal pb-[2rem]">
        Ce qui t'attends
      </h1>

      {/* Stepper */}
      <div className="flex">
        <div
          id="left_colonne_stepper"
          className="hidden w-1/3 h-full md:flex justify-center items-center"
        >
          {/* <img
            alt="image_left_colonne_stepper"
            src={step_picture}
            className="w-1/2 rounded-full relative z-10"
          /> */}
        </div>
        <div id="step_programs" className="w-full md:w-1/3 relative">
          {steps.map((step, index) => (
            <div
              key={step.number}
              className="flex items-start align-start relative"
            >
              {index !== steps.length - 1 && (
                <div className="absolute top-0 left-[21px] bg-[#99AFBF] w-[1px] h-full z-0"></div>
              )}
              <div className="font-bold mr-4 px-[1rem] py-[0.5rem] border border-[#] rounded-full z-10 bg-white">
                {step.number}
              </div>
              <div>
                <h2 className="text-oceanH1 text-lato text-base font-medium leading-tight">
                  {step.title}
                </h2>
                <p className="w-[90%] text-[#01385FB2] text-lato text-l font-extralight mb-[2.25rem]">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          id="right_colonne_stepper"
          className="hidden w-1/3 h-full md:flex flex-col justify-center items-center"
        >
          {/* <img
            alt="image_right_colonne_stepper_1"
            src={step_picture2}
            className="hidden w-1/2 md:flex align-center items-start pl-[4rem] relative z-10"
          />
          <img
            alt="image_right_colonne_stepper_2"
            src={step_picture3}
            className="hidden w-[70%] md:flex align-center items-end mt-auto ml-4 relative z-10"
          /> */}
        </div>
      </div>

      <div className="w-full lg:w-[40%] bg-[#fcead9] text-[#00205b] p-4 rounded-lg mb-6 text-center">
        <p className="font-bold text-[#09929d] text-xl">IMPORTANT</p>
        <p className="font-bold">
          J’adapte ce parcours à vos besoins à chaque étape <br/> du bilan de
          compétences pour du sur-mesure ! <span role="img">👌</span>
        </p>
      </div>

      {/* Bouton */}
      <div className="flex justify-center w-full mb-8">
        <button className="bg-[#09929d] text-white py-2 px-6 rounded-lg font-semibold">
          Je suis prêt(e) à commencer !
        </button>
      </div>
    </div>
  );
};

export default StepperWithImportantSection;
