const Testimonial = () => {
  return (
    <div className="w-full bg-[#002955] py-12 flex flex-col items-center">
      <p
        className="text-white font-lato text-[18px] font-light text-center max-w-4xl mx-auto  leading-7"
        style={{ fontWeight: 300 }}
      >
        "Merci beaucoup à Margot qui m’a permis de prendre du recul sur mes
        valeurs et sur ce qui est finalement essentiel pour mon épanouissement
        professionnel. De très bons raisonnement et des questions pertinentes."
      </p>
      <p className="mt-6 text-[#00cccc] font-lato text-[18px] leading-[28px] font-semibold text-center">
        Jennifer - Assistante administrative
      </p>
    </div>
  );
};

export default Testimonial;
