import React from 'react'
import HeroSection from './HeroSection'
// import Formation from './Formation'
import Testimonial from './Testimonial'
import MultiAdventureComponent from './BilanCompetence'
import Avis from './Avis'
import PricingFAQ from './PricingFAQ'
import Objectif from './Objectif'
import Stepper from './Stepper'

function Bilan() {
  return (
    <div>
        <HeroSection />
        <Objectif />
        {/* <Formation /> */}
        <Testimonial />
        <MultiAdventureComponent />
        <Avis />
        <Stepper />
        <PricingFAQ />
    </div>
  )
}

export default Bilan