import React from "react";
import firstProgramImage from "../../medias/succes.jpg";
import secondProgramImage from "../../medias/travail_moderne.jpg";
import thirdProgramImage from "../../medias/equilibre_passions_carriere.jpg";
// import { Link } from "react-router-dom";


function Articles() {
  const programme = [
    {
      title: "Multipotentiels : transformer votre polyvalence en succès entrepreneurial",
      text: "En tant que multipotentiels, avec des talents et intérêts multiples, vous devez souvent vous heurter à certains dilemmes professionnels...",
      imageUrl: firstProgramImage,
      button: "Découvrir l’article",
      link:'/transformer_votre_polyvalence_en_succes_entrepreneurial',
    },
    {
      title: "Multipotentiels : vous êtes un atout dans le monde du travail moderne",
      text: "Avez-vous mille passions, des intérêts qui varient presque autant que les saisons, et une soif insatiable d'apprendre...",
      imageUrl: secondProgramImage,
      button: "Découvrir l’article",
      link:'/vous_etes_un_atout_dans_le_monde_du_travail',
    },
    {
      title: "Multipotentiels : comment équilibrer vos passions et votre carrière ?",
      text: "Vous êtes du genre à jongler entre une multitude de centres d’intérêt, à sauter d’une passion à l’autre avec enthousiasme, ...",
      imageUrl: thirdProgramImage,
      button: "Découvrir l’article",
      link:'/comment_equilibrer_vos_passions_et_votre_carriere',
    },
  ];

  return (
    <div className="w-full bg-white py-12 flex flex-col items-center">
      <h3 className="text-oceanH1 font-yesevaOne text-3xl md:text-4xl mb-8">
        Quelques articles
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl px-6">
        {programme.map((article, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              src={article.imageUrl}
              alt=""
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h4 className="font-lato text-oceanH1 text-xl font-bold mb-4">
                {article.title}
              </h4>
              <div className="w-12 border-b-4 border-turquoise mb-4"></div>
              <p className="text-darkBlue text-base mb-6">
                {article.text}
              </p>
              <a
                href={article.link}
                className="inline-block text-dark border-2 border-dark py-2 px-4 rounded-md font-bold hover:bg-dark hover:text-white transition-colors"
              >
                {article.button}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articles;
