import React from "react";
import Seance from "../../medias/seance.svg";

function Description() {
  const description = [
    {
      imageUrl: Seance,
      title: "Séances personnalisées",
      text: (
        <>
          <p>
            Je propose des séances et des programmes de coaching entièrement
            personnalisés, conçus pour répondre spécifiquement à tes besoins et
            aspirations uniques. Chaque individu est différent, avec des défis
            et des ambitions qui lui sont propres. C'est pourquoi j'élabore des
            solutions sur mesure. Que tu cherches à améliorer tes compétences en
            leadership, à gérer ton stress, à trouver un meilleur équilibre
            entre vie professionnelle et personnelle, ou à explorer de nouvelles
            opportunités de carrière, je suis là pour te guider à chaque étape
            de ton parcours.
          </p>
        </>
      ),
      button: {
        text: "En savoir plus",
        url: "",
      },
    },
  ];

  return (
    <div className="w-full flex justify-center pt-4 md:pt-10">
      {description.map((description, index) => (
        <div
          key={index}
          id="description_margot"
          className="w-full bg-[#fff0df] flex flex-col lg:flex-row items-center justify-between lg:px-20 pt-8 pb-8 md:pb-[4rem]"
        >
          <div
            id="picture_section"
            className="w-full lg:w-[30%] flex justify-center lg:justify-start"
          >
            <img
              alt="Séances personnalisées"
              src={description.imageUrl}
              className="w-full px-4  max-w-md ml-0 lg:ml-[6rem] object-cover"
            />
          </div>
          <div
            id="text_description"
            className="w-full lg:w-[60%] lg:pl-20 text-left flex flex-col justify-end"
          >
            <h4 className="py-4 md:px-0 px-[1rem]  text-oceanH1 text-2xl font-yesevaOne lg:text-[40px]">
              {description.title}
            </h4>
            <p className="md:px-0 px-[1rem] text-oceanH2 leading-7 text-base md:text-lg">
              {description.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Description;
