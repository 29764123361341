import React, { useState } from "react";
import margotImage from "../../medias/margot_ordinateur.png";

// Flèches
import up from "../../medias/arrow_up.svg";
import down from "../../medias/arrow_down.svg";

const PricingFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(index);
    }
  };

  return (
    <div className="bg-white">
      {/* Section : Qui prend soin des participants ? */}
      <section className="px-4 bg-[#fff0df]  lg:px-10 flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-center lg:mt-0 lg:self-end order-2 lg:order-1">
          <img
            src={margotImage}
            alt="Margot"
            className="max-w-[80%] lg:max-w-[60%] rounded-md"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:pr-12 order-2 lg:order-1 md:my-0 my-8">
          <h3 className="text-[#00205b] font-yesevaOne md:mt-10 text-[30px] font-bold md:mb-0 mb-8 px-[1rem] md:px-0 ">
            Qui anime la communauté ?{" "}
          </h3>
          <p className="px-[1rem] md:px-0 lg:w-[100%] lg:pt-8 leading-7 text-oceanH2 mb-6">
            Je suis coach professionnelle chez Joy Blast. Ma mission est
            d’accompagner les multipotentiels à explorer et exploiter leur
            potentiel pour transformer leur carrière. Cette formation
            spécialement conçue pour coacher les multipotentiels allie des
            méthodes de coaching professionnel, de développement personnel et de
            mentorat pour vous aider à atteindre vos objectifs. Dans cette
            communauté, je vous propose d’être accompagné par moi et la
            communauté, pour trouver votre voie en équilibrant vos passions et
            réaliser vos projets les plus ambitieux.
          </p>
          <a
            href="/a_propos"
            className="block rounded-md bg-[#00205b] text-white md:mb-10 mt-6 px-4 py-2 font-semibold shadow-md hover:bg-[#001a47] w-fit"
          >
            En savoir plus sur Margot{" "}
          </a>
        </div>
      </section>

      {/* Section : Je rejoins la Multi Adventure */}
      <section  id="join" className="py-12 px-4 lg:px-20 text-center">
        <h2  className="text-[#00205b] font-yesevaOne text-[30px] font-bold mb-12">
          Je m’inscris à la communauté{" "}
        </h2>

        <div className="flex flex-col lg:flex-row justify-center space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="bg-[#fff0df] rounded-md py-8 px-6 shadow-lg w-full lg:w-1/3">
            <p className="font-lato text-[#002955] text-[18px] font-[400] mb-2">
              Paiement en 12 fois
            </p>
            <p className="text-[#00205b] text-[30px] font-bold mb-8">14€ / mois</p>
            <a href='https://buy.stripe.com/eVaaGX7BEaXxaGccMO' target="_blank" rel="noopener noreferrer" className="mt-4 px-4 py-2  bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je suis convaincu(e) !
            </a>
          </div>

          <div className="bg-[#ffe2c8] rounded-md py-8 px-6 shadow-lg w-full lg:w-1/3">
            <p className="font-lato text-[#002955] text-[18px] font-[400] mb-2">
              Paiement en 1 fois
            </p>
            <p className="text-[#00205b] text-[30px] font-bold line-through">
              168€
            </p>
            <p className="mb-8 text-[#00205b] text-[30px] font-bold">120€</p>
            <a href='https://buy.stripe.com/6oEcP54ps5DdcOk8wz' target="_blank" rel="noopener noreferrer" className="mt-4 px-4 py-2 bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je passe à l'action !
            </a>
          </div>
        </div>
      </section>

      {/* Section : FAQ */}
      <section
        className="py-12 px-4 lg:px-20"
        style={{
          background: "linear-gradient(to right, #ffa115, #ffb74a)",
        }}
      >
        <h2 className="text-white font-yesevaOne text-[30px] font-bold text-center mb-8">
          FAQ - Questions fréquentes
        </h2>

        <div className="max-w-4xl mx-auto">
          {/* FAQ Items */}
          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(1)}
            >
              <span className="text-[#00205b] font-semibold">
                Quelles sont les activités proposées dans cette communauté ?{" "}
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 1 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 1 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Nous nous rencontrons pour discuter 2 fois par mois et 4
                ateliers sont organisés dans l’année sur des thématiques clés
                pour les multipotentiels : trouver l’équilibre entre passion et
                carrière, la gestion du temps, améliorer son image en tant que
                multipotentiel, ...
              </div>
            )}
          </div>

          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(2)}
            >
              <span className="text-[#00205b] font-semibold">
                Est-ce que la communauté se rencontre 100% à distance ou en
                présentiel ?{" "}
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 2 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 2 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Toutes les ressources, activités et rencontres de la communauté
                sont en ligne via une plateforme privée. Il est possible que
                plusieurs personnes habitent dans une même région, dans ce cas
                vous être libre, de votre propre initiative, de vous rencontrer
                en face à face si vous le souhaitez ! 😀
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <a href="#join" className="mt-4 px-4 py-2  bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
            Rejoindre la Joy Community{" "}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingFAQ;
