import React from "react";
// import step_picture from "../../medias/picture_step_left.svg";
// import step_picture2 from "../../medias/picture1_step_right.svg";
// import step_picture3 from "../../medias/picture2_step_right.svg";

const Stepper = () => {
  const steps = [
    {
      number: 1,
      title: "J’apprends à me reconnecter à moi-même",
      description:
        "Se reconnecter à soi-même pour trouver la clarté, la direction, et la source d'une vie épanouissante et authentique.",
    },
    {
      number: 2,
      title: "Je m’entoure",
      description:
        "Trouver des personnes qui vont me soutenir pour bénéficier de conseils, d'encouragements et d'expertise pour augmenter mes chances de succès dans ma transition.",
    },

    {
      number: 3,
      title: "Je lâche mes idées reçues",
      description:
        "Apprendre à identifier et à lâcher mes croyances inconscientes qui me freinent au quotidien.",
    },

    {
      number: 4,
      title: "Mais qui suis-je ?",
      description:
        "Découvrir l'essence de ce qui me met en mouvement, me donne envie, comble mes besoins, mes forces, mes faiblesses, ma zone de génie, ... . Pour trouver un métier qui fait sens.",
    },
    {
      number: 5,
      title: "J’imagine mon métier idéal",
      description:
        "Identifier ensemble plusieurs pistes de métiers compatibles avec ma personnalité et mes aspirations;",
    },
    {
      number: 6,
      title: "Je passe à l’action",
      description:
        "Découvrir différentes pistes de métiers compatibles via des immersions, entretiens, évènements, ...",
    },
    {
      number: 7,
      title: "Je choisi mon métier idéal et je monte mon plan d’action",
      description:
        "Faire le point pour identifier ce qui m'a fait le plus vibrer, identifier mon métier idéal et co-construire un plan d’action pour l’atteindre.",
    },
  ];

  return (
    <div className="mt-[2.5rem] flex flex-col justify-center align-center items-center lg:pt-[2.5rem] lg:pb-[2rem] px-[1rem] lg:bg-background_step bg-white relative">
      <h1 className="text-oceanH1  font-yesevaOne text-4xl font-normal leading-10 tracking-normal text-left pb-[2rem]">
        Ce qui t'attends
      </h1>
      <div className="flex">
        <div
          id="left_colonne_stepper"
          className=" hidden w-1/3 h-full md:flex justify-center items-center"
        >
          {/* <img
            alt="image_left_colonne_stepper"
            src={step_picture}
            className="w-1/2 rounded-full relative z-10"
          /> */}
        </div>
        <div id="step_programs" className="w-full md:w-1/3 relative">
          {steps.map((step, index) => (
            <div
              key={step.number}
              className="flex items-start align-start relative"
            >
              {index !== steps.length - 1 && (
                <div className="absolute top-0 left-[21px] bg-[#99AFBF] w-[1px] h-full z-0"></div>
              )}
              <div className="font-bold mr-4 px-[1rem] py-[0.5rem] border border-[#] rounded-full z-10 bg-white">
                {step.number}
              </div>
              <div>
                <h2 className="text-oceanH1 text-lato text-base font-medium leading-tight">
                  {step.title}
                </h2>
                <p className="w-[90%]  text-[#01385FB2] text-lato text-l font-extralight mb-[2.25rem]">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          id="right_colonne_stepper"
          className="hidden w-1/3 h-full md:flex flex-col justify-center items-center"
        >
          {/* <img
            alt="image_left_colonne_stepper"
            src={step_picture2}
            className="hidden w-1/2 md:flex align-center items-start pl-[4rem] relative z-10"
          />
          <img
            alt="image_left_colonne_stepper"
            src={step_picture3}
            className="hidden w-[70%] md:flex align-center items-end mt-auto ml-4 relative z-10"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
