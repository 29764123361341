import React from "react";
import couverture from "../../../medias/table_couverture.png";

// Composant réutilisable pour chaque section
const Section = ({ title, content, isList = false }) => {
  return (
    <section className="flex flex-col py-2">
    <div className="">
      <h2 className="text-2xl md:text-3xl pb-4 lg:text-4xl font-bold text-[#1E2A39] font-yesevaOne">
        {title}
      </h2>
      {isList ? (
        <ul className="list-disc ml-6 text-oceanH2 leading-7 text-justify md:pb-8 text-base md:text-l">
          {content.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <p className="text-oceanH2 leading-7 text-justify text-base md:pb-8 md:text-l">
          {content}
        </p>
      )}
    </div>
  </section>
  );
};

const Content = () => {
  // Mise à jour avec l'article complet

  const introduction = `Avez-vous mille passions, des intérêts qui varient presque autant que les saisons, et une soif insatiable d'apprendre de nouvelles choses ? Si c'est le cas, vous pourriez bien être un multipotentiel ! Et devinez quoi ? C’est de moins en moins un “inconvénient” dans le monde du travail. La société est en pleine transformation et ne recherche plus uniquement des experts hyper-spécialisés.`;

  // const section1 = `Dans un marché où tout évolue à la vitesse de la lumière, vous êtes exactement le genre de personne dont le monde du travail moderne a besoin : une personne capable de s'adapter rapidement et de porter plusieurs casquettes à la fois.`;

  const forces = [
    "Polyvalence accrue : Vous pouvez facilement passer d'une tâche à l'autre, endosser différents rôles et exceller dans chacun d'eux.",
    "Résolution innovante de problèmes : Votre cerveau peut faire des associations riches d’idées provenant de différents domaines, vous permettant de penser en dehors des sentiers battus.",
    "Vision globale : Grâce à vos connaissances dans plusieurs domaines, vous comprenez comment chaque pièce s'emboîte pour former l’ensemble du projet.",
    "Facilitation des collaborations interdisciplinaires : Vous comprenez suffisamment bien différents métiers pour servir de passerelle entre les équipes."
  ];

  const evolution = `Le monde du travail d’aujourd’hui n’a plus grand-chose à voir avec celui forgé par l’industrialisation du siècle dernier. À cette époque, la spécialisation pour optimiser la production dominait, mais aujourd’hui, la polyvalence et l'agilité mentale sont très recherchées.`;

  const challenges = [
    "Dispersion : Évitez de vous éparpiller, apprenez à prioriser.",
    "Perfectionnisme : Lâchez prise, mieux vaut fait que parfait.",
    "Syndrome de l’imposteur : Acceptez que vous ne maîtriserez pas tout à 100%, mais vos capacités d'adaptation sont inestimables.",
    "Identité professionnelle cohérente : Construisez une image claire et compréhensible pour votre audience."
  ];

  const conclusion = `Dans un monde professionnel en pleine mutation, les multipotentiels sont des atouts précieux. Leur polyvalence, créativité et capacité à naviguer entre différents domaines sont des compétences de plus en plus recherchées. Il est temps d’embrasser cette pluralité et de la transformer en atout pour votre carrière !`;

  return (
    <div className="w-full">
      {/* Header Section with background image */}
      <div className="relative h-[300px] md:h-[400px] lg:h-[500px] bg-gray-800">
        <img
          src={couverture}
          alt="Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-3xl text-center md:text-5xl lg:text-6xl font-bold text-white font-yesevaOne">
            Multipotentiels : vous êtes un atout dans le monde du travail moderne
          </h1>
        </div>
      </div>

      {/* Content Sections */}
      <div className="md:px-[20%] md:py-[4rem] px-[10%] py-[2rem] ">
        <Section title="Introduction" content={introduction} />
        <Section
          title="Les forces des multipotentiels dans le monde du travail"
          content={forces}
          isList={true}
        />
   {/* GIF intégré */}
   <div className="my-6 flex justify-center">
        <img
            src="https://media.giphy.com/media/6muxKZ9Lm2J3HhlRc9/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>
        <Section
          title="Un monde du travail en évolution"
          content={evolution}
        />
        <Section title="Les défis spécifiques" content={challenges} isList={true} />

        {/* Deuxième GIF intégré */}
        <div className="my-6 flex justify-center">
        <img
            src="https://media.giphy.com/media/PyLenXkpbAIGZQh6iQ/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>

        <Section title="Conclusion" content={conclusion} />
     
        {/* GIF intégré */}
        <div className="my-6 flex justify-center">
        <img
            src="https://media.giphy.com/media/Efffx408vNds7CZolY/giphy.gif"
            alt="Adaptabilité"
            className="w-1/2"
          />
        </div>
     
      </div>
    </div>
  );
};

export default Content;
