import React from "react";
import desktopImage from "../../medias/image_margot.svg"; // Image pour desktop
import mobileImage from "../../medias/margot_mobile.png"; // Image pour mobile

function Banner() {
  return (
    <div className="w-full md:h-1/2-screen bg-white overflow-hidden pt-[9vh] md:pt-0 md:mt-12">
      {/* Conteneur principal */}
      <div className="flex flex-col md:flex-row md:justify-between max-w-screen-xl mx-auto px-4 md:px-6 lg:h-[62vh] h-fit md:pr-0 md:pt-0 lg:pr-0 py-8">
        
        {/* Section texte */}
        <div className="w-full md:w-2/3 flex flex-col justify-center text-center md:text-left">
          <h1 className="text-oceanH1 font-yesevaOne text-3xl md:text-5xl lg:text-6xl leading-tight tracking-tight mb-4 md:pt-24">
            Coaching pour <br />
            <span className="text-[#f06600]">Multipotentiels</span>
          </h1>
          <h2 className="font-lato text-lg md:text-2xl lg:text-3xl font-light leading-relaxed tracking-tight text-oceanH2 mb-6">
            À ton service pour te guider, booster ton énergie et ne plus être seul(e) pour réaliser la vie et la carrière que tu désires 
          </h2>
          {/* Bouton pour mobile seulement */}
          <div className="md:hidden flex justify-center mx-4">
            {/* <a href='/contactez-moi' className="bg-[#002d72] w-screen text-white py-3 px-6 rounded-md text-lg">
              C’est parti !
            </a> */}
          </div>
        </div>

        {/* Section image */}
        <div className="w-full md:w-[100%] flex justify-center items-center mt-4 md:mt-0">
          {/* Image pour desktop */}
          <img
            src={desktopImage}
            alt="illustration desktop"
            className="hidden md:block w-full object-cover rounded-xl"
          />
          {/* Image pour mobile */}
          <img
            src={mobileImage}
            alt="illustration mobile"
            className="block md:hidden w-full object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
