import React from "react";
import classNames from "classnames";

const Meeting = () => {
  return (
    <div className="bg-cover bg-meeting h-[90vh]  p-[2.5rem] flex items-center justify-center">
      <div className="max-w-md p-8 bg-white rounded-md shadow-md">
        <p
          className={classNames(
            "text-2xl font-bold",
            "color:var(--DARK-BLUE---100, #002955)",
            "pb-[1.5rem]"
          )}
        >
          Merci, c’est dans la boîte ! 💪
        </p>

        <p className="text-darkBlue font-lato text-base font-normal w-full lg:w-full mb-4 px-4 lg:p-0">
          Merci d’avoir complété ce quizz, vos réponses vont vraiment m’aider à
          cibler quel type d'accompagnement est fait pour toi.
        </p>

        <p
          className="text-darkBlue font-lato text-base font-normal w-full lg:w-full text-justify  mb-4 px-4 lg:p-0
          "
        >
          Et maintenant il est temps d’ouvrir l’agenda pour prendre rendez-vous
          ensemble :
        </p>

        <a
          href="https://calendly.com/joyblast-coaching/30min?month=2024-01"
          target="_blank"
          rel="noopener noreferrer"
          className="w-fit flex items-center bg-green-blue text-white py-3 px-[1.5rem] rounded"
        >
          Prendre rendez-vous gratuitement
        </a>
      </div>
    </div>
  );
};

export default Meeting;
