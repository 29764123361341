import React from "react";

import Banner from "./Banner";
import Description from "./Description";
import Bonus from "./Bonus";
import Funfact from "./Funfact";
import ContactBanner from "../ContactBanner";

function About() {
  return (
    <div className="main_section">
      <Banner />
      <Description />
      <Bonus />
      <Funfact />
      <ContactBanner />
    </div>
  );
}

export default About;
