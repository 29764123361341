import React from "react";

function Condition() {
  return (
    <div className="max-w-2xl mx-auto mt-8 md:mt-24 p-4">
      <h1 className="text-3xl font-bold mb-4">Conditions génerales de vente</h1>
      <p className="text-l font-bold"> Date d'effet : 07/03/2024</p>
      <p className="text-l font-bold mb-2"> Mise à jour : 07/03/2024</p>
      <h3 className="py-8 text-xl font-bold mb-2">
        PRÉAMBULE. Désignation des co-contractants
      </h3>
      <p>
        Les présentes Conditions Générales de Vente des prestations proposées
        par MARGOT BATOUFFLET (ci-après dénommée les <strong> « CGV » </strong>)
        constituent, conformément à l'article L. 441-1 du Code du Commerce, le
        socle unique de la relation commerciale entre les parties.
      </p>
      <br />
      <p>
        Elles ont pour objet de définir les conditions dans lesquelles MARGOT
        BATOUFFLET (ci-après dénommée <strong>«MARGOT BATOUFFLET EI» </strong>),
        fournit au Client (ci-après désigné le « Client ») qui lui en fait la
        demande, les services qui seront désignés ci-après.
      </p>
      <br />
      <ul>
        <li>
          MARGOT BATOUFFLET EI : la domiciliation est fixée à
          TOSSE (40230), 58 AVENUE DU GENERAL DE GAULLE, en tant que
          micro-entreprise sous le numéro SIRET 923 343 842 00012. MARGOT
          BATOUFFLET EI peut être contactée aux coordonnées suivantes :
        </li>
        <br />
        <li>- Numéro de téléphone : 06 25 46 04 02</li>
        <li>
          - Adresse mail :{" "}
          <a href="mailto:joyblast-consulting@gmail.com">
            joyblast-consulting@gmail.com
          </a>
        </li>
        <br />
        <li>
          Client : est considérée comme le Client toute personne physique ou
          morale majeure, conformément aux lois du pays dans lequel il réside,
          qui valide une commande sur le site internet (ci-après dénommée
          <strong> «Site»</strong>) accessible au lien l’URL suivant :
          <a target="_blank" rel="noreferrer" href="https://joyblast-coaching.com">
  https://joyblast-coaching.com
</a>
          , les réseaux sociaux de MARGOT BATOUFFLET EI ou bien également à
          partir d’une correspondance écrite (mail ou courrier postal) avec
          MARGOT BATOUFFLET EI.
        </li>
      </ul>
      <h3 className="py-8 text-xl font-bold mb-2">
        Article 1. Champ d’application du contrat
      </h3>
      <p>
        Ces CGV s'appliquent, sans restriction ni réserve, à toutes les
        prestations de service rendues par MARGOT BATOUFFLET EI, quel que soit
        les clauses pouvant figurer sur les documents du Client et notamment ses
        conditions générales d'achat.
      </p>
      <br />
      <p>
        <strong>Acceptation des CGV :</strong> le Client ayant recours aux
        services de MARGOT BATOUFFLET EI reconnaît avoir pris connaissance et
        accepté les présentes CGV avant toute validation de commande. Pour
        adhérer aux CGV et valider sa commande, le Client procède de la façon
        suivante : l’acceptation est reconnue avec la mention « Je reconnais
        avoir pris connaissance des CGV et les accepte sans réserve » indiquée
        au moment du paiement de chaque prestation de MARGOT BATOUFFLET EI. Le
        contrat prend effet le jour de sa signature.
      </p>
      <br />
      <p>
        <strong>Partage des CGV :</strong> conformément à la réglementation en
        vigueur, ces CGV sont systématiquement communiquées à tout Client qui en
        fait la demande, pour lui permettre de passer commande auprès de MARGOT
        BATOUFFLET EI et figurent directement sur le site internet{" "}
        <a target="_blank" rel="noreferrer" href="https://joyblast-coaching.com">
  https://joyblast-coaching.com
</a>
        .
      </p>
      <br />
      <p>
        <strong>Conditions particulières :</strong> MARGOT BATOUFFLET EI se
        réserve le droit de déroger à certaines clauses des présentes CGV, en
        fonction des négociations menées avec le Client, par l'établissement de
        conditions particulières qui pourraient figurer aux termes du devis, sur
        un bon de commande ou un avenant aux CGV validé par le Client.
      </p>
      <br />
      <p>
        <strong>Révision des informations :</strong> les renseignements et
        tarifs figurant sur le Site de MARGOT BATOUFFLET EI, ainsi que sur ses
        prospectus ou plaquettes publicitaires sont donnés à titre indicatif et
        sont révisables à tout moment. MARGOT BATOUFFLET EI est en droit d'y
        apporter toutes modifications qui lui paraîtront utiles.
      </p>
      <h2 className="text-2xl font-bold mt-6 mb-4">
        Article 2. Prestations réalisées
      </h2>
      <h3 className="py-8 text-xl font-bold mb-2">2.1. Produits & services</h3>
      <p>
        MARGOT BATOUFFLET EI développe des produits numériques et prestations de
        service destinés aux personnes souhaitant engager sereinement une
        transition dans leur vie professionnelle. MARGOT BATOUFFLET EI propose
        ainsi à ses Clients :
      </p>
      <br />
      <ul>
        <li>
          {" "}
          - Une <strong>formation en ligne « Multi Adventure »</strong> destinée
          à accompagner les profils multipotentiels à explorer et exploiter leur
          potentiel pour transformer leur carrière. Cette formation allie des méthodes
          de coaching professionnel, de développement personnel et de mentorat pour
          les aider à atteindre leurs objectifs. La formation dure 3 mois et demi
          et sera accessible à partir du Site et dispensée sur la plateforme externe
          SchoolMaker à partir de Avril 2025. Les pré-inscriptions sont déjà ouvertes;
          {" "}
        </li>
        <br />
        <li>
          {" "}
          - Un <strong>programme « Bilan de compétences »</strong> destiné à accompagner
          le client dans l’analyse de ses compétences, expériences, passions, intérêts, …
          et pour définir plusieurs trajectoires professionnelles qui fera sens. Le programme
          est composé de 12 séances individuelles sur 3 mois minimum. La durée peut s'étendre
          sur 5 mois maximum;{" "}
        </li>
        <br />
        <li>
          {" "}
          - L'accès à une <strong>communauté en ligne « Joy Community »</strong> 
          destinée à partager et s'entraider entre multipotentiels. Les échanges
          et activités organisées dans le cadre de la communauté seront dispensés
          à partir de la plateforme externe Slack;{" "}
        </li>
        <br />
        <li>
          - Des <strong>programmes</strong> de séances individuelles de conseil
          et de coaching (en présentiel ou en visioconférence) pour accompagner
          les personnes dans leur transition professionnelle (changer de
          carrière, faire évoluer son poste, réorienter ses compétences ou
          trouver une passion professionnelle); a noter : le contenu et le
          déroulement des programmes peuvent évoluer en fonction des objectifs
          retenus avec le Client en cours de séance. Si l'accompagnement
          nécessite plus de séances et de continuer dans la durée, un nouveau
          devis sera réalisé.{" "}
        </li>
        <br />
        <li>
          - Des <strong>masterclass</strong> destinées à apprendre à se découvrir
          soi-même pour trouver une voie professionnelle et de manière plus générale à
          répondre aux défis que rencontrent les multipotentiels (faire de sa passion
          un business, le syndrome de l'imposteur, la gestion du temps et des priorités,...);
        </li>
        <br />
        <li>
          {" "}
          - Des <strong>ateliers</strong> en ligne et en présentiel pour animer la
          communauté des clients (Joy Community);
        </li>
        <br />
        <li>
          - Des <strong>ebooks</strong> payants et gratuits accessibles à partir du
          Site, du Slack communautaire et/ou des réseaux sociaux de MARGOT BATOUFFLET EI.
        </li>
        {/* ... d'autres éléments de la liste ... */}
      </ul>
      <br />
      <p>
        L’ensemble des caractéristiques de ces prestations de services se trouve
        détaillé sur le Site de MARGOT BATOUFFLET EI, ainsi que sur ses
        brochures commerciales et ses autres supports de communication.
      </p>
      <br />
      <p>
        <strong>Spécificités sur les bonus :</strong> MARGOT BATOUFFLET EI aura
        la possibilité d’offrir des bonus à ceux qui achètent ses prestations de
        service. Ces bonus ne sont pas garantis et ne sont pas disponibles
        pendant toute la durée de la promotion et du déroulé du programme. Ces
        bonus varient en fonction des promotions prévues au cours de l'année.
      </p>
      <br />
      <p>
        <strong>Clôture d'une prestation :</strong> : dans l'éventualité où
        MARGOT BATOUFFLET EI déciderait de mettre un terme à un Programme,
        Masterclass ou une Formation en ligne, elle devra en notifier le Client
        au minimum trente (30) jours à l'avance et lui donner la possibilité de
        télécharger l'ensemble des ressources en amont.
      </p>
      <br />
      {/* Ajoutez la section "Clôture d’une prestation" ici */}
      <h3 className="py-8 text-xl font-bold mb-2">
        2.2. Informations pré-contractuelles
      </h3>
      <p>
        <strong>Prestations non thérapeutiques :</strong> le Client est informé
        que les produits et services proposés par MARGOT BATOUFFLET EI n’ont
        aucune visée thérapeutique. L'objectif principal du conseil
        professionnel est d'aider les individus à résoudre des problèmes
        spécifiques, à prendre des décisions éclairées et à améliorer leurs
        compétences en matière de prise de décision. Il se concentre souvent sur
        des situations liées au travail, à l'éducation, aux relations ou à
        d'autres aspects de la vie quotidienne. En revanche, la thérapie a pour
        objectif de traiter des problèmes émotionnels, psychologiques ou
        psychiatriques plus profonds. Elle peut inclure des aspects liés à la
        santé mentale, aux traumatismes passés, aux troubles mentaux, etc.
      </p>
      <h3 className="text-lg font-bold my-8">
        Article 3. Les obligations des parties
      </h3>
      <div className="mb-4">
        <p className="font-bold">3.1. Engagements des parties</p>
        <p className="mb-2">
          Accès aux formations en ligne : MARGOT BATOUFFLET EI s'engage à
          maintenir un accès aux formations en ligne contenant les vidéos,
          documents, PDF et ressources et autres supports d'informations. Le
          Client aura accès au Programme aussi longtemps qu'il existe, avec un
          minimum de cent vingt (120) jours.
        </p>
        <p className="mb-2">
          Visuels non contractuels : MARGOT BATOUFFLET EI informe le Client que
          les photographies ou graphismes présentés sur ses différents supports
          de communication (site web, brochures, cartes de visite, flyer, etc.)
          ne sont pas contractuelles et ont pour simple vocation de donner une
          image des prestations réalisées. En ce sens, MARGOT BATOUFFLET EI ne
          saurait être tenu responsable en conséquence d’une quelconque
          différence entre l’esthétisme du produit ou la technique utilisée et
          les visuels mis en avant dans la communication.
        </p>
        <p className="mb-2">
          Aucune obligation de résultat : MARGOT BATOUFFLET EI s’engage à
          employer tous les moyens nécessaires dans la réalisation de sa mission
          pour répondre aux objectifs recherchés par le Client dans le cadre de
          sa commande, en revanche elle ne saurait être tenue à une obligation
          de résultat. MARGOT BATOUFFLET EI ne pourra en aucun cas garantir le
          succès des formations appliquées au Client. En ce sens, il est rappelé
          que MARGOT BATOUFFLET EI vend des Produits et ne dispense que des
          Prestations de formations destinées à améliorer la connaissance de soi
          et la recherche de son épanouissement professionnel.
        </p>
      </div>
      <div className="mb-4">
        <p className="font-bold">3.2. Secret professionnel</p>
        <p>
          Le secret professionnel est une obligation morale selon l’éthique et
          le code de déontologie du Code de déontologie du Conseil en Evolution
          Professionnelle - Syntec Conseil 2020. MARGOT BATOUFFLET EI s’engage à
          garder l’anonymat du Client, ainsi que tout élément permettant de
          l’identifier.
        </p>
      </div>
      <div className="mb-4">
        <p className="font-bold">3.3. Référencement en droit de diffusion</p>
        <p>
          Toutefois, MARGOT BATOUFFLET EI se réserve le droit d’utiliser tout ou
          partie du contenu de la prestation (motif, compte rendu, retour et
          témoignage du Client) à des fins pédagogiques et promotionnelles sur
          ses différents supports de communication. MARGOT BATOUFFLET EI
          demandera en amont l’accord du Client pour faire figurer des faits
          concrets qui le concerne, sans dévoiler des éléments qui pourraient
          permettre d’identifier l'identité exacte de ce dernier. L’identité
          exacte du Client pourra être dévoilée, si celui-ci l’accepte par un
          accord écrit à MARGOT BATOUFFLET EI. Le Client pourra néanmoins
          revenir sur cette décision à tout moment en le faisant savoir à MARGOT
          BATOUFFLET EI. MARGOT BATOUFFLET EI aura alors un délai de 15 jours
          pour supprimer le contenu en question.
        </p>
        <p>
          Le Client accepte que MARGOT BATOUFFLET EI puisse faire figurer parmi
          ses références la Prestation accomplie pour son compte. A ce titre,
          MARGOT BATOUFFLET EI demandera en amont l’accord du Client pour faire
          figurer une partie de son identité, ainsi que son logo sur une liste
          de références. La diffusion de ces références pourra se faire par voie
          de communiqués de presse, d’annonces, de publicités ou de toute autre
          communication à caractère technique, commercial ou promotionnel
          relative à la Prestation, et cela dans tout type de média (internet,
          télévision, radio, presse écrite, salon professionnel ou grand public,
          etc… et ce, sans que cette liste soit limitative), sur tout type de
          support (support imprimé ou numérique, brochures, film, vidéo, etc …
          et ce, sans que cette liste soit limitative), dans toutes les langues
          et dans tous les pays, y compris en utilisant ou en combinant tout ou
          partie des données ou images collectées (sauf information
          préalablement désignée par le Client comme étant confidentielle). En
          cas de refus de la part du Client, ce dernier doit adresser un
          courrier recommandé avec accusé de réception à MARGOT BATOUFFLET EI
          motivant son refus de diffusion.
        </p>
      </div>
      <h3 className="text-lg font-bold my-4">Article 4. Tarifs</h3>
      <p className="mb-2">
        Le prix des prestations est exprimé en euros avec la TVA non applicable
        selon l’article 293 B du Code Général des Impôts (C.G.I.). Les tarifs
        des prestations en vigueur par MARGOT BATOUFFLET EI sont ceux indiqués
        dans les présentes CGV. Pour les prestations "sur-mesure", le tarif des
        prestations sera indiqué et fourni sur un devis au préalable.
      </p>
      <p className="mb-2">
        Pour bénéficier de la formation en ligne "Multi Adventure", le Client devra régler
        à MARGOT BATOUFFLET EI : la somme de neuf cent quatre vinght seize euros (996€) TTC en une
        (1) mensualité au jour de la passation de la commande, ou la somme de
        neuf cent quatre vingt seize euros (996€) en trois (3) mensualités de trois cent trente deux euros (332€).
      </p>
      <p className="mb-2">
        Pour bénéficier au programme "Bilan de compétences", le Client devra régler
        à MARGOT BATOUFFLET EI : la somme de mille cent dix euros (1110€) TTC en une
        (1) mensualité au jour de la passation de la commande, ou la somme de
        mille cent dix euros (1110€) en trois (3) mensualités de trois cent soixante dix euros (370€).
      </p>
      <p className="mb-2">
        Pour bénéficier de l'accès à la communauté en ligne "Joy Community", le Client devra régler
        à MARGOT BATOUFFLET EI : la somme de cent vingt euros (120€) TTC en une
        (1) mensualité pour un an au jour de la passation de la commande, ou la somme de
        cent soixante huit euros (168€) en douze (12) mensualités de quartorze euros (14€).
      </p>
      <p className="mb-2">
        Paiement en plusieurs fois : si le Client opte pour le paiement en
        plusieurs fois, il demeure responsable de ses mensualités à moins
        d'obtenir un remboursement en accord avec la Politique de Remboursement
        de la prestation. Le Client s'engage à ne pas annuler ou suspendre ses
        paiements. Dans l'éventualité où un paiement ne serait pas honoré,
        MARGOT BATOUFFLET EI se réserve le droit de suspendre immédiatement la
        prestation du Client.
      </p>
      <p className="mb-2">
        Révision des tarifs : MARGOT BATOUFFLET EI se réserve le droit de
        réviser ses tarifs à tout moment sans toutefois pouvoir affecter les
        commandes en cours déjà validées. MARGOT BATOUFFLET EI se réserve
        également le droit, hors cette période de validité, de répercuter sans
        préavis sur ses tarifs toute nouvelle taxe ou tout changement relatif
        aux taux d’imposition.
      </p>
      <p className="mb-2">
        Non-inclu dans les tarifs : les tarifs ne comprennent pas les frais de
        traitement, d'expédition, de transport et de livraison, qui sont
        facturés en supplément, dans les conditions indiquées sur le Site
        Internet et calculés préalablement à la passation de la commande dans le
        montant total de l'achat.
      </p>
      <p className="mb-2">
        Offres promotionnelles : MARGOT BATOUFFLET EI peut être amenée à
        proposer sur ses supports de communication des offres promotionnelles
        collectives ou individuelles. Celles-ci ne sont valables que dans la
        limite de la durée de validité et/ou d’un nombre d’utilisations indiqués
        sur ces mêmes supports. Toutefois, en aucun cas le Client ne pourra
        exiger l'application d’offres promotionnelles n'étant plus en vigueur le
        jour de la commande.
      </p>
      <p className="mb-2">
        Facturation : une facture est établie par MARGOT BATOUFFLET EI et remise
        au Client lors de la réception du paiement de chaque prestation.
      </p>
      <h3 className="text-lg font-bold my-4">Article 5. Commandes</h3>

      <div className="mb-2">
        <h4 className="font-bold">5.1. Commande sur le site</h4>
        <p>
          Le Client peut procéder à la commande d’une prestation de MARGOT
          BATOUFFLET EI selon les modalités indiquées dans les CGV. La
          validation de la commande implique :
        </p>
        <ul className="list-disc list-inside">
          <li>
            La sélection des prestations souhaitées par le biais du site par le
            Client;
          </li>
          <li>
            L'acceptation des présentes CGV et le paiement comptant de ces
            prestations sélectionnées;
          </li>
          <li>
            Un mail ou un courrier de confirmation de la commande et une facture
            sont dès lors automatiquement transmis au Client à l’adresse mail
            que ce dernier aura préalablement pris le soin d’indiquer, à défaut
            il appartient au Client de vérifier sa démarche et éventuellement de
            la réitérer.
          </li>
        </ul>
        <p>
          Spécificité aux formations en ligne : pour les formations en ligne,
          l’accès se fait par la plateforme SchoolMaker. Une fois la commande passée
          et la validation du paiement effectuée, le Client recevra en plus par
          mail ses identifiants de connexion qu’il devra conserver de manière
          privée et sécurisée afin de pouvoir accéder à sa ou ses formations.
        </p>
      </div>

      <div className="mb-2">
        <h4 className="font-bold">5.2. Commande par correspondance</h4>
        <p>
          En cas de difficulté pour procéder à sa commande, le Client peut
          contacter MARGOT BATOUFFLET EI à l’adresse mail suivante :
          joyblast-consulting@gmail.com. Le Client peut alors procéder à la
          commande d’une prestation de MARGOT BATOUFFLET EI par mail. La
          validation de la commande implique :
        </p>
        <ul className="list-disc list-inside">
          <li>
            Le Client indique par écrit (mail) ou à l’oral à MARGOT BATOUFFLET EI les
            références des prestations qu’il souhaite commander, telles
            qu’indiquées sur les supports de communication de MARGOT BATOUFFLET
            EI, le moyen de paiement qu’il envisage d’utiliser et la date ou
            période à laquelle il souhaiterait que la prestation soit exécutée;
          </li>
          <li>
            MARGOT BATOUFFLET EI, transmet un devis au Client qu’il doit
            retourner signé pour valider la commande des prestations souhaitées.
          </li>
          <li>
            MARGOT BATOUFFLET EI valide la faisabilité de la commande et le
            moyen de paiement, sous réserve de ses disponibilités et de la
            possibilité de recourir audit moyen de paiement, et fait parvenir au
            Client un devis au Client que celui ce dernier devra retourner
            signé;
          </li>
          <li>
            Le Client devra alors, pour finaliser la transaction, procéder au
            paiement de sa commande : comptant ou partiel en 3 fois sans frais ou 6 fois sans frais,
            selon les modalités de paiement des prestations communiquées pour
            les prestations souhaitées. Ce paiement doit avoir lieu dans les 7
            jours suivant la validation de la commande du Client par MARGOT
            BATOUFFLET EI et selon le moyen de paiement convenu.
          </li>
          <li>
            Un mail ou un courrier avec une facture sont dès lors
            automatiquement transmis au Client à l’adresse mail que ce dernier
            aura préalablement pris le soin d’indiquer dans les précédents
            échanges, à défaut il appartient au Client de vérifier cette
            démarche et d’éventuellement réitérer la demande de facture.
          </li>
        </ul>
      </div>

      <div className="mb-2">
        <h4 className="font-bold">5.3. Limites sur les commandes</h4>
        <p>
          Droit de rectification : le Client a la possibilité de vérifier le
          détail de sa commande, son prix total et de corriger d'éventuelles
          erreurs avant de confirmer son acceptation (article 1127-2 du Code
          Civil). L'acceptation de la commande et de l'intégralité des présentes
          CGV constituent une preuve du contrat de vente. Toute commande passée
          sur le Site constitue la formation d'un contrat conclu à distance
          entre le Client et MARGOT BATOUFFLET EI. Il appartient donc au Client
          de vérifier l'exactitude de la commande et de signaler immédiatement
          toute erreur.
        </p>
        <p>
          Limite des stocks : les prestations et offres sont valables tant
          qu'elles sont visibles sur le Site et dans la limite des stocks
          disponibles. MARGOT BATOUFFLET EI ne pourra être tenue responsable des
          ruptures de stock ou de l'impossibilité de vendre un produit ou une
          prestation dont le stock est inexistant.
        </p>
        <p>
          Limite en cas d’indisponibilité : en cas d’indisponibilité après
          passation de la commande, MARGOT BATOUFFLET EI en informera le Client
          dans les plus brefs délais par email ou par courrier. La commande sera
          alors automatiquement annulée et le Client remboursé au plus tard dans
          les quinze (15) jours de la notification de l’annulation. MARGOT
          BATOUFFLET EI se réserve le droit d'annuler ou de refuser toute
          commande d'un Client avec lequel il existerait un litige relatif au
          paiement d'une commande antérieure.
        </p>
        <h2 className="text-lg font-bold my-4">Article 6. Paiement</h2>

        <div className="mb-2">
          <h4 className="font-bold">6.1. Modalités de paiement</h4>
          <p>
            Le Client s’engage à payer le prix en contrepartie de la prestation
            opérée par MARGOT BATOUFFLET EI conformément au montant indiqué dans
            le bon de commande ou la facture qui lui ont été transmis. Le
            paiement est exigible immédiatement à la date de la commande, y
            compris pour les produits en précommande. Le prix est payable
            comptant, en totalité au jour de la passation de la commande ou en 3
            fois sans frais pour certaines prestations (se référer aux modalités
            de règlement de chaque prestation sur le Site).
          </p>
          <p>
            Les moyens de paiements acceptés par MARGOT BATOUFFLET EI sont les
            suivants :
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>Paiement par carte bancaire :</strong> lorsque la commande
              est passée sur le Site, le Client est invité à l’étape « Paiement
              » à renseigner les informations présentes sur sa carte bancaire
              sur Stripe: le propriétaire de la carte, son modèle – Visa, Carte
              Bleue, MasterCard, autres cartes bleues -, son numéro, sa date de
              fin de validité, son code de sécurité. Selon l’organisme bancaire,
              le Client peut être amené à devoir opérer une validation
              supplémentaire sur le site ou l’application de sa banque. Le
              paiement est confirmé et la commande validée lorsqu’une fenêtre «
              Paiement validé » s'affiche sur le site.
              <br />
              <em>
                A noter: le paiement sécurisé en ligne par carte bancaire est
                réalisé par l’intermédiaire de la société Stripe. Les
                informations transmises sont chiffrées par un logiciel dans les
                règles de l’art et ne peuvent être lues au cours du transport
                sur le réseau. Toute garantie quant à la sécurité de ce système
                est entièrement de la responsabilité de la société Stripe et ne
                saurait être imputée à MARGOT BATOUFFLET EI.
              </em>
            </li>
            <li>
              <strong>Paiement par Paypal ou Gocardless :</strong> lorsque la commande est
              passée par correspondance avec le prestataire, le Client est
              invité à opérer un virement via Paypal (site web ou application)
              en renseignant le courriel du prestataire ou via la plateforme Gocardless par prélèvement SEPA. Le paiement est
              confirmé et la commande validée à réception d’un courriel de la
              part du prestataire.
            </li>
            <li>
              <strong>Paiement par virement bancaire :</strong> lorsque la
              commande est passée par correspondance avec le prestataire, le
              Client est invité à opérer un virement via l’établissement, le
              Site ou l’application de son organisme bancaire en renseignant les
              coordonnées bancaires du prestataire qui lui auront été
              communiquées préalablement par courriel, sur le bon de commande ou
              la facture – si établis en amont du paiement. Le paiement est
              confirmé et la commande validée à réception d’un courriel de la
              part du prestataire.
            </li>
          </ul>
          <p>
            A réception du paiement comptant, MARGOT BATOUFFLET EI transmet au
            Client une facture valant reconnaissance de son paiement. La facture
            peut être établie sur le même support que le bon de commande.
          </p>
        </div>

        <div className="mb-2">
          <h4 className="font-bold">6.2. Délais de paiement</h4>
          <p>
            Aucun délai de paiement n’est accepté, sauf accord exprès de MARGOT
            BATOUFFLET EI. Tout retard de paiement non accordé ou non justifié
            par un cas de force majeure autorise MARGOT BATOUFFLET EI à user de
            son droit de suspendre l’exécution de la prestation de services
            jusqu’au complet paiement du prix.
          </p>
          <p>
            En cas de défaut de paiement total ou partiel à la date convenue,
            MARGOT BATOUFFLET EI est autorisée à réclamer au Client des
            pénalités de retard au taux d'intérêt légal en vigueur. Le paiement
            desdites pénalités ne peut être exécuté par le biais d’une
            compensation.
          </p>
          <p>
            Le Client professionnel devient de plein droit redevable d’une
            indemnité forfaitaire de 40€ au titre des frais de recouvrement en
            sus des pénalités de retard. MARGOT BATOUFFLET EI se réserve le
            droit de refuser une autre commande émanant du Client en cas de
            problèmes d’impayés ou de retards de paiement.
          </p>
          <p>
            Les paiements effectués par le Client ne seront considérés comme
            définitifs qu'après encaissement effectif des sommes dues à MARGOT
            BATOUFFLET EI. En outre, MARGOT BATOUFFLET EI se réserve le droit,
            en cas de non-respect des conditions de paiement figurant ci-dessus,
            de suspendre ou d'annuler la livraison des commandes en cours
            effectuées par le Client.
          </p>
          <h2 className="text-3xl font-bold mb-4">
            Article 7. Réalisation d’une prestation
          </h2>

          <div className="mb-2">
            <h4 className="font-bold">7.1. Date de réalisation</h4>
            <p>
              La réalisation de la prestation ou le commencement de celle-ci
              peuvent être fixés à une date convenue entre les parties selon les
              modalités suivantes :
            </p>
            <ul className="list-disc list-inside">
              <li>
                Si le Client n’a pas renoncé à son droit de rétractation : cette
                date peut être fixée à compter du lendemain de la fin dudit
                délai.
              </li>
              <li>
                Si le Client a renoncé à son droit de rétractation : cette date
                peut être fixée dès le jour de cette renonciation ou à n’importe
                quelle date ultérieure convenue expressément entre les parties.
                En cas d’indisponibilité, le Client est en mesure de demander à
                MARGOT BATOUFFLET EI un report de l’exécution de la prestation
                jusqu’à 1 heure avant la date convenue. La nouvelle date sera
                fixée d’un commun accord entre les parties.
              </li>
            </ul>
          </div>

          <div className="mb-2">
            <h4 className="font-bold">7.3. Absence</h4>
            <p>
              Si le Client venait à ne pas se présenter à la date convenue, dans
              les 15 minutes qui suivent le début de la séance, sans en avoir
              préalablement avisé MARGOT BATOUFFLET EI, il ne saurait demander
              le remboursement du paiement versé préalablement. En revanche, il
              pourra, en cas de motif légitime et justifié, demander le report
              de l’exécution de la prestation à une nouvelle date convenue avec
              MARGOT BATOUFFLET EI.
            </p>
          </div>

          <h2 className="text-3xl font-bold mb-4">
            Article 8. Droit de rétractation et d'annulation
          </h2>

          <div className="mb-2">
            <h4 className="font-bold">8.1. Droit de rétractation</h4>
            <p>
              Le Client est informé qu’il dispose, conformément au Code de la
              consommation, d’un droit de rétractation de 14 jours à compter de
              la validation de sa commande, sauf pour les produits mentionnés à
              l’article L221-28 du Code de la Consommation, notamment les
              contenus numériques fournis sur un support immatériel.
            </p>
            <p>
              Dans le cas d’achat d’un produit numérique qui pourrait être
              visionné et/ou téléchargé immédiatement après l’achat, le Client
              renonce à son droit de rétractation dans un délai de 14 jours.
            </p>
            <p>
              Pour les prestations de service, le Client peut choisir que la
              prestation soit exécutée sans attendre la fin du délai de
              rétractation à la condition d’y renoncer. Cette renonciation peut
              être tacite dès lors que le Client propose ou accepte de fixer la
              date d’exécution de la prestation avant la fin de son délai de
              rétractation.
            </p>
            <p>
              Lorsque la date d’exécution de la prestation a été fixée après la
              fin du délai de rétractation, le Client peut, dans les 14 jours
              suivant validation de sa commande, exercer son droit de
              rétractation par notification expresse et écrite (formulaire en
              ligne, mail ou courrier postal daté et signé), exprimant sa
              volonté de façon claire et dénuée d’ambiguïté, adressée à MARGOT
              BATOUFFLET EI.
            </p>
            <p>
              En cas d'exercice du droit de rétractation dans le délai susvisé,
              MARGOT BATOUFFLET EI s’engage à rembourser intégralement le Client
              des sommes versées par lui au titre de la prestation dans un délai
              de 14 jours à compter de la réception de la notification. À
              l’échéance du délai de rétractation ou en cas de renonciation
              audit délai, le Client sera autorisé à demander l’annulation de la
              prestation avec remboursement partiel du paiement (5% seront
              prélevés du montant, équivalent aux frais de transaction
              bancaire).
            </p>
            <p>
              Si la prestation de service ne peut pas faire l’objet d’une
              exécution pleine et entière pour des faits imputables à MARGOT
              BATOUFFLET EI, sans solution alternative convenant au Client, il
              sera procédé au remboursement intégral de ce dernier dans les plus
              brefs délais, sauf cas de force majeure.
            </p>
          </div>

          <div className="mb-2">
            <h4 className="font-bold">8.2. Annulation</h4>
            <p>
              Le Client qui, de façon expresse ou tacite, met fin à la relation
              contractuelle qui l’engage auprès de MARGOT BATOUFFLET EI avant
              son terme et en dehors de son délai de rétractation autorise
              MARGOT BATOUFFLET EI à conserver 5% (cinq pourcents) du paiement,
              équivalent aux frais de transaction bancaire.
            </p>
            <p>
              Aussi, le Client s’engage à régulariser tout éventuel montant
              complémentaire indiqué dans la facture et selon les justificatifs
              émis par MARGOT BATOUFFLET EI pour l’exécution partielle ou totale
              de sa prestation.
            </p>
            <p>
              Chaque partie se réserve le droit de mettre fin, avec un préavis
              de 7 jours, à la relation contractuelle qui l’unit à l’autre avant
              son terme et sans préjudice aux droits de cette dernière par
              notification expresse et écrite (formulaire en ligne, mail ou
              courrier postal daté et signé) dans le cas où l’attitude de
              l’autre partie rendrait impossible la continuité de la relation
              contractuelle dans de bonnes conditions (recours à des injures ou
              à des faits de violence physique ou morale, demandes contraires à
              la loi et aux bonnes mœurs ou à la probité et à l’honneur du
              cocontractant). Dans le cas où ces faits seraient imputables à
              MARGOT BATOUFFLET EI, cette dernière s’engage à rembourser
              l’intégralité des sommes versées par le Client. Dans le cas où les
              faits seraient imputables au Client, ce dernier s’engage à régler
              toute la prestation effectuée.
            </p>
            <h2 className="text-3xl font-bold mb-4">
              Article 9. Engagements, responsabilités &amp; force majeure
            </h2>

            <div className="mb-2">
              <h4 className="font-bold">9.1. Engagements</h4>
              <p>
                MARGOT BATOUFFLET EI s’engage à mettre en œuvre tous les moyens
                dont elle dispose, à employer tous les soins et toutes les
                diligences nécessaires à la réalisation de sa prestation dans
                les meilleures conditions. Afin de limiter les risques d’une
                mauvaise exécution de la prestation et d’anticiper son service
                après-vente, MARGOT BATOUFFLET EI s’engage à :
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Préciser au Client toutes les informations dont elle a besoin
                  pour la bonne réalisation de sa prestation,
                </li>
                <li>
                  Évaluer préalablement les besoins du Client afin de considérer
                  la compatibilité de ceux-ci avec ses pratiques
                  professionnelles,
                </li>
                <li>
                  Transmettre au Client toutes les recommandations et
                  préconisations qu’il doit appliquer préalablement et/ou
                  ultérieurement à la réalisation de la prestation, MARGOT
                  BATOUFFLET EI rectifiera dans la mesure du possible les
                  services jugés défectueux dans les meilleurs délais suivant la
                  constatation par lui-même de tout défaut évoqué, ou en cas
                  d’impossibilité, procèdera au remboursement intégral du Client
                  dans le même délai.
                </li>
              </ul>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">9.2. Responsabilités</h4>
              <p>
                <span className="font-bold">
                  Responsabilité pour le contenu du site :
                </span>{" "}
                MARGOT BATOUFFLET EI ne pourra être tenue responsable pour toute
                donnée qui s’avèrerait être obsolète, inexacte ou faisant
                défaut. Toute rédaction de contenus est soumise à un travail
                préalable de recherches minutieuses et approfondies. Les données
                et informations dans les formations et mises en ligne sur le
                site https://joyblast-coaching.com (et ceux des partenaires)
                sont sujettes à des modifications et évolutions.
              </p>
              <p>
                <span className="font-bold">
                  Responsabilité pour le fonctionnement du site :
                </span>{" "}
                MARGOT BATOUFFLET EI ne pourra être tenue responsable de toutes
                interruptions, inaccessibilités, dysfonctionnements ou plus
                généralement de tout dommage direct ou indirect, occasionné par
                l’utilisation ou la consultation du site ou de tout autre
                service proposé. Cela quelle que puisse en être la cause,
                l’origine, les conséquences ou la nature (indisponibilité
                temporaire du site, virus informatique, etc).
              </p>
              <p>
                <span className="font-bold">
                  Responsabilité pendant et après la réalisation de la
                  prestation :
                </span>{" "}
                MARGOT BATOUFFLET EI ne saurait être tenue responsable des
                dommages et préjudices que le Client peut connaître pendant et
                après la réalisation de la prestation. Cela s’applique en cas
                d’éventuels dommages et préjudices, moraux ou financiers, liés
                aux actions du Client mises en place à la suite de la
                réalisation de la prestation. MARGOT BATOUFFLET EI ne saurait
                également être tenue responsable d’un dommage résultant d’une
                négligence ou d’une faute imputable au Client ou à un tiers au
                présent contrat. Cela quelles que puissent en être les
                conséquences : manque à gagner, manque de chance, actions
                éventuellement intentées par des tiers à l’encontre du Client,
                etc.
              </p>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">9.3. Force majeure et cas fortuit</h4>
              <p>
                Les Parties ne pourront être tenues pour responsables si la
                non-exécution ou le retard dans l'exécution de l'une quelconque
                de leurs obligations, telles que décrites dans les présentes
                découle d'un cas de force majeure, au sens de l'article 1218 du
                Code Civil. Constitue un cas de force majeure l’intervention
                d’une circonstance indépendante de la volonté des parties
                empêchant l’exécution normale de leurs obligations. À savoir,
                tous faits ou circonstances imprévisibles, irrésistibles et
                extérieurs aux parties.
              </p>
              <p>
                Constitue un cas fortuit l’intervention d’une circonstance
                indépendante de la volonté des parties empêchant l’exécution
                normale de leurs obligations, bien que non extérieure à la
                partie qui l’invoque. À savoir qu’elle ne pouvait ni
                l’anticiper, ni y résister. Le cas de force majeure ou le cas
                fortuit est reconnu comme une cause d’exonération des
                obligations contractuelles des parties entraînant leur
                suspension.
              </p>
              <p>
                Chaque partie s’engage à informer immédiatement son
                cocontractant de la survenance d’un cas de force majeure, ainsi
                que de la disparition de ce cas. Sont considérés, de façon non
                exhaustive, comme cas de force majeure ou cas fortuits, outre
                ceux habituellement retenus par la jurisprudence des cours et
                des tribunaux français : la survenance d’une guerre affectant
                directement le pays de résidence ou de domiciliation des
                parties, le blocage des moyens de transport ou de
                l’approvisionnement des premières nécessités, une catastrophe
                naturelle ou un évènement climatique exceptionnel, l’arrêt des
                réseaux de télécommunication ou difficultés propres aux réseaux
                de télécommunication externes, une coupure d’électricité ou une
                panne de machines rendant impossible l’exécution dans les délais
                prévus de la prestation.
              </p>
              <p>
                Les parties s’engagent à examiner ensemble l’incidence de
                l’événement et à convenir des conditions dans lesquelles
                l’exécution du contrat pourra être poursuivie. En cas de
                prolongement du cas de force majeure supérieur à 7 jours, le
                présent contrat pourra être résilié à la demande de l’un des
                cocontractants.
              </p>
            </div>

            <h2 className="text-3xl font-bold mb-4">
              Article 10. Confidentialité et données personnelles
            </h2>

            <div className="mb-2">
              <h4 className="font-bold">10.1. Politique de confidentialité</h4>
              <p>
                MARGOT BATOUFFLET EI accorde une grande importance à la
                protection de vos informations personnelles et s’engage à
                respecter la réglementation en vigueur applicable au traitement
                des données à caractère personnelle qui pourraient être
                collectées.
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Le Règlement Général sur la Protection des Données (RGPD)
                  2016/679 du Parlement européen et du Conseil du 27 avril 2016,
                </li>
                <li>
                  La Loi Informatique et Libertés n° 78-17 du 6 janvier 1978
                  relative à l'informatique, aux fichiers et aux libertés
                  modifiée par la Loi n° 2018-493 du 20 juin 2018 relative à la
                  protection des données personnelles pour les questions de
                  traitement de données à caractère personnel,
                </li>
                <li>
                  La Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                  l'économie numérique (LCEN) : pour toute suppression de
                  contenus litigieux.
                </li>
              </ul>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">10.2. Les données collectées</h4>
              <p>
                MARGOT BATOUFFLET EI collecte et traite les données personnelles
                que vous fournissez volontairement, telles que votre nom,
                prénom(s), adresse e-mail et numéro de téléphone, lorsque vous
                utilisez les services, remplissez des formulaires ou communiquez
                avec elle.
              </p>
              <p>
                Définition des données personnelles : ce sont des informations
                permettant d’identifier directement ou indirectement une
                personne physique, à savoir ici le Client ou l’Utilisateur.
              </p>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">10.3. Engagements sur les données</h4>
              <p>
                MARGOT BATOUFFLET EI s’engage dans le cadre de la collecte à :
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Ne recourir à aucun partenaire commercial ou sous-traitant,
                </li>
                <li>
                  Ne jamais communiquer ces données à des tiers autres, sauf
                  accord express préalable du Client et de l’Utilisateur,
                </li>
                <li>
                  Traiter ces données de façon non-discriminatoire ou contraire
                  à la législation ou la réglementation en vigueur, à l’ordre
                  public et aux bonnes mœurs,
                </li>
                <li>
                  Assurer un suivi des données personnelles qu’elle conserve.
                </li>
              </ul>
              <p>
                Limite d’engagement : MARGOT BATOUFFLET EI étant une
                micro-entreprise, le Client et l’Utilisateur sont informés que
                cette dernière n’est pas tenue à une obligation de registre
                renforcé des activités de traitement effectuées sous sa
                responsabilité.
              </p>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">10.4. Conservation des données</h4>
              <p>
                Les données collectées peuvent être conservées et stockées
                temporairement à l’aide d’outils numériques tels que : Outlook,
                Gmail, Notion, Mailchimp.
              </p>
              <p>
                Les données personnelles sont conservées pendant une durée de :
              </p>
              <ul className="list-disc list-inside">
                <li>
                  3 ans si le Client ou l’Utilisateur est inactif depuis cette
                  durée ou si les données sont traitées en dehors de l’Union
                  Européenne,
                </li>
                <li>
                  13 mois si l’Utilisateur du site refuse de renouveler son
                  consentement pour le traitement des cookies (délai de
                  réitération obligatoire incombant à l’entreprise),
                </li>
                <li>
                  1 mois à compter de toute demande de rectification, de
                  suppression ou de restitution des données émise par le Client
                  ou l’Utilisateur, ce délai est de 3 mois en cas de demande
                  complexe (Exemple : demande concernant l’intégralité des
                  données) et de 8 jours pour des données de santé,
                </li>
                <li>
                  10 ans pour les données contenues dans les pièces et
                  justificatifs comptables à compter de la clôture de
                  l’exercice. MARGOT BATOUFFLET EI s’engage à conserver tout
                  contact supprimé dans un registre afin de ne plus le
                  solliciter et de pouvoir justifier des opérations réalisées
                  antérieurement relatives à ses données.
                </li>
              </ul>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">
                10.5. Finalités de la collecte de données
              </h4>
              <p>
                Le site https://joyblast-coaching.com est géré en toute
                conformité avec les dispositions du Règlement Général sur la
                Protection des Données entre en vigueur le 25 Mai 2018. Les
                données personnelles recueillies sur le Client ou l’Utilisateur
                que peut être amenée à collecter MARGOT BATOUFFLET EI à partir
                de ce dernier, de façon directe ou indirecte, rentre dans le
                cadre de son activité et sont traitées dans le cadre des
                finalités suivantes :
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Emails : les données personnelles recueillies sur le site
                  résultent de la communication volontaire d'une adresse de
                  courrier électronique. Les adresses e-mail ainsi recueillies
                  ne servent qu'à transmettre les éléments d'information
                  demandés. Les adresses électroniques collectées ne feront
                  l'objet d'aucune cession à des tiers.
                </li>
                <li>
                  Les données de trafic : les données de trafic ont uniquement
                  pour finalité de permettre d'analyser la fréquentation des
                  pages d'information sur site afin d'en améliorer le contenu.
                </li>
                <li>
                  Cookies : les données relatives à la navigation des visiteurs
                  ne sont pas exploitées nominativement. Il s'agit de
                  statistiques agrégées permettant de connaître les pages les
                  plus et les moins populaires, les chemins préférés, les
                  niveaux d'activité par jour de la semaine et par heure de la
                  journée, les principales erreurs clients ou serveur, etc…
                </li>
              </ul>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">10.6. Sécurité des données</h4>
              <p>
                MARGOT BATOUFFLET EI s’engage à mettre en œuvre tous les moyens
                nécessaires pour préserver la sécurité et la confidentialité des
                données personnelles recueillies, contre tout accès non
                autorisé, altération, divulgation, ou destruction. Et, en cas de
                violation avérée, MARGOT BATOUFFLET EI s’engage à aviser le
                Client et l’Utilisateur dès sa découverte.
              </p>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">
                10.7. Droit d’accès et de rectification
              </h4>
              <p>
                Conformément au RGPD et à l'article 34 de la loi 78-17 du 6
                janvier 1978 relative à l'information, aux fichiers et aux
                libertés, vous disposez du droit d'accéder à vos données
                personnelles, de les rectifier, de les supprimer, de restreindre
                leur traitement, de vous opposer au traitement et à la
                portabilité des données. Pour exercer vos droits ou pour toute
                information concernant vos données personnelles, vous pouvez
                nous contacter par courrier à l'adresse suivante par email à
                l’adresse suivante : joyblast.consulting@gmail.com
              </p>
              <p>
                En cas de désaccord persistant concernant vos données, vous avez
                le droit de saisir la CNIL à l’adresse suivante : Commission
                Nationale Informatique et Libertés 3, place de Fontenoy, 75007
                Paris N° de téléphone : 01 53 73 22 22 Ou en utilisant le lien
                suivant :
                [https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil](https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil)
              </p>
            </div>

            <div className="mb-2">
              <h4 className="font-bold">10.8. Cookies</h4>
              <p>
                <span className="font-bold">Fonctionnement des cookies :</span>{" "}
                lors de cette visite sur le site https://joyblast-coaching.com,
                MARGOT BATOUFFLET EI peut enregistrer des “cookies”, sous
                réserve de vos choix, sur votre ordinateur qui ont pour but de
                stocker des informations relatives à votre navigation sur le
                site (les pages que vous avez consultées, la date et l'heure de
                la consultation, etc.). Ces cookies ne permettent pas de vous
                identifier. La durée de conservation de ces informations dans
                votre ordinateur est d’une durée de 13 mois maximum.
              </p>
              <p>
                <span className="font-bold">Désactivation des cookies :</span>{" "}
                vous pouvez vous opposer à l'enregistrement des cookies en
                choisissant de supprimer ou désactiver les cookies au niveau de
                votre navigateur. Chaque navigateur propose une solution de
                paramétrage pour vous signaler les cookies qui sont déposés dans
                votre ordinateur et vous permet de les accepter ou de les
                refuser, au cas par cas systématiquement.
              </p>
              <p>
                <span className="font-bold">Configuration :</span> la
                configuration de chaque navigateur est différente. Nous vous
                invitons à vous reporter au menu d’aide de votre navigateur
                (Microsoft Internet Explorer ou Mozilla Firefox ou Google Chrome
                ou Safari ou Opéra) pour paramétrer celui-ci selon vos souhaits.
              </p>
              <h2 className="text-3xl font-bold mb-4">
                Article 11. Propriété intellectuelle
              </h2>
              <p className="mb-4">
                MARGOT BATOUFFLET EI conserve la propriété intellectuelle pleine
                et exclusive, ainsi que les droits d’auteur, sur tous les
                éléments accessibles sur son site web, les contenus dans ses
                prestations, ses brochures commerciales et autres supports de
                communication, quelle que soit leur nature : graphique,
                textuelle ou audio, ainsi que les logiciels.
              </p>
              <p className="mb-4">
                L’intégralité de ce site est assujettie à la législation
                française et internationale sur le droit d’auteur et la
                propriété intellectuelle. Toute copie, reproduction,
                représentation, diffusion, modification ou traduction partielle
                ou totale est, selon les dispositions du Code de la Propriété
                Intellectuelle et des traités et accords internationaux,
                interdite sans l’autorisation expresse de MARGOT BATOUFFLET.
                Dans le cas du non-respect de cette clause, s’expose à des
                poursuites judiciaires pour délit de contrefaçon.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-3xl font-bold mb-4">
                Article 12. Droit applicable et langue
              </h2>
              <p className="mb-4">
                Les présentes Conditions Générales de Vente (CGV) sont soumises
                pour leur interprétation et leur exécution à la loi française.
                Elles sont rédigées en langue française. Dans le cas où elles
                seraient traduites en une ou plusieurs langues, seul le texte
                français ferait foi en cas de litige.
              </p>
            </div>

            <h2 className="text-3xl font-bold mb-4">
              Article 13. Gestion des litiges
            </h2>
            <p className="mb-4">
              <span className="font-bold">13.1. Gestion amiable</span>
              <br />
              Pour toute réclamation relative à l’exécution de la prestation, le
              Client s’engage à privilégier une résolution amiable et contacter
              prioritairement MARGOT BATOUFFLET EI par écrit. Dans le cas où,
              dans un délai de 14 jours, les solutions proposées par MARGOT
              BATOUFFLET EI n'apportent pas satisfaction au Client consommateur,
              ou s’il se trouvait dans l’impossibilité de résoudre le problème
              évoqué, le Client accepte de procéder à une tentative de médiation
              devant un professionnel ou, à son choix, de recourir à tout mode
              alternatif de règlement des différends, avant l’engagement de
              toute procédure judiciaire.
            </p>
            <p className="mb-4">
              <span className="font-bold">
                13.2. Médiation et règlement des litiges
              </span>
              <br />
              Conformément aux dispositions du Code de la Consommation relatives
              au « processus de médiation des litiges de la consommation », le
              Client a la faculté de recourir gratuitement et aux conditions
              fixées par ledit code, à une demande de résolution amiable par
              voie de médiation, dans un délai inférieur à un an à compter de sa
              réclamation écrite auprès du professionnel.
            </p>
            <p className="mb-4">
              Conformément aux articles L.616-1 et R.616-1 du Code de la
              Consommation, MARGOT BATOUFFLET EI a désigné, par adhésion
              enregistrée sous le numéro 44017 auprès de BAYONNE MEDIATION comme
              entité de médiation de la consommation. Pour saisir le médiateur,
              le consommateur doit formuler sa demande :
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>
                Soit par écrit à l’adresse : 32 rue du Hameau, 64200 Biarritz,
                Nouvelle Aquitaine, France
              </li>
              <li>Soit par mail à : consommation@bayonne-mediation.com</li>
              <li>
                Soit via le formulaire en ligne :{" "}
                <a target="_blank" rel="noreferrer" href="https://www.bayonne-mediation.com/contact">
  https://www.bayonne-mediation.com/contact
</a>
              </li>
            </ul>
            <p className="mb-4">
              Quel que soit le moyen de saisine utilisé, la demande doit
              impérativement contenir :
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>
                Les coordonnées postales, téléphoniques et électroniques du
                demandeur
              </li>
              <li>
                Le nom et l’adresse et le numéro d’enregistrement chez BAYONNE
                MÉDIATION, du professionnel concerné
              </li>
              <li>
                Un exposé succinct des faits. Le consommateur précisera au
                médiateur ce qu’il attend de cette médiation et pourquoi
              </li>
              <li>Copie de la réclamation préalable</li>
              <li>
                Tous documents permettant l’instruction de la demande (bon de
                commande, facture, justificatif de paiement, etc …)
              </li>
            </ul>
            <p className="mb-4">
              À l’occasion d’un litige entre professionnels et à défaut d’accord
              amiable, le litige sera porté à la connaissance du Tribunal
              compétent dans les conditions de droit commun.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-3xl font-bold mb-4">
              Article 14. Modification des CGV
            </h2>
            <p className="mb-4">
              MARGOT BATOUFFLET EI se réserve le droit de modifier les présentes
              CGV, ses offres et ses tarifs à tout moment, sans toutefois que
              cela puisse avoir une incidence sur les commandes en cours. Le
              Client est garanti de rester soumis aux dispositions en vigueur au
              jour de la signature du présent contrat.
            </p>
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-4 md:mb-0">
                <h3 className="font-bold">
                  Engagements de MARGOT BATOUFFLET EI :
                </h3>
                <ul className="list-disc list-inside">
                  <li>
                    À informer les utilisateurs à l'avance de toute modification
                    significative des CGV. Cela peut être fait par le biais
                    d'une notification sur le site, par e-mail, ou par d'autres
                    moyens de communication appropriés.
                  </li>
                  <li>
                    Si la législation le requiert, MARGOT BATOUFFLET EI
                    demandera explicitement le consentement des utilisateurs
                    pour les nouvelles CGV.
                  </li>
                  <li>À partager la date de la dernière mise à jour.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <p className="mb-4">
              <h2 className="text-3xl font-bold mb-4">
                Article 15. Indépendance des clauses & Acceptation
              </h2>
              <br />
              <span className="font-bold">15.1. Indépendance des clauses</span>
              <br />
              Les clauses comprises dans les présentes CGV sont indépendantes
              les unes des autres. L’invalidité ou l’inopposabilité de l'une
              quelconque de ces clauses, en vertu d'une loi ou d'un règlement,
              ou bien encore à la suite d'une décision exécutoire d'une
              juridiction ou d'une autorité administrative compétente, ne
              saurait entraîner la nullité intégrale du présent contrat. Seule
              la clause visée serait réputée non écrite.
            </p>
            <p className="mb-4">
              <span className="font-bold">15.2. Acceptation</span>
              <br />
              Le Client reconnaît avoir pris connaissance de l’ensemble des CGV
              précédentes et de son annexe (politique des confidentialité) et
              consent expressément et sans réserve au présent contrat.
              L’acceptation du Client est reconnue par le règlement de la
              prestation avec la mention « Je reconnais avoir pris connaissance
              des CGV et les accepte sans réserve » indiquée au moment du
              paiement en ligne pour valider la commande avec MARGOT BATOUFFLET
              EI. Le Client déclare renoncer, de ce fait, à se prévaloir de tout
              document contradictoire et, notamment, de ses propres conditions
              générales d'achat, qui seront inopposables à MARGOT BATOUFFLET EI,
              même s'il en a eu connaissance.
            </p>
            <p className="mb-4">
              <h2 className="text-3xl font-bold mb-4">
                Article 16. Élection de domicile
              </h2>
              <br />
              Toutes notifications devant être effectuées dans le cadre de
              l’exécution des présentes CGV seront considérées comme réalisées
              si elles sont faites par email avec demande d’avis de réception ou
              lettre recommandée avec demande d’avis de réception aux adresses
              suivantes :
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>
                À MARGOT BATOUFFLET EI : 58 Avenue du General de Gaulle, 40230,
                Tosse, France.
              </li>
              <li>
                Au Client : à l’adresse indiquée par le Client au jour de la
                signature des présentes CGV.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Condition;
