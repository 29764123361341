import React from "react";
import { Link } from "react-router-dom";

function Result({ answers, questions }) {
  

  return (
    <div className=" h-[80vh] w-full flex flex-col justify-center items-center">
      <div>
        <Link to="/">
          <button className="text-white bg-green-blue px-2 py-1 rounded text-xl absolute top-10 right-20">X</button>
        </Link>
      </div>
      <div className="flex flex-col align-start justify-start transition-opacity">
        <h2 className="text-ocean font-lato font-extrabold text-2xl my-2">Résultats du Quiz</h2>

        <ul>
          {questions.map((question, index) => (
            <li key={question.id} className="py-2">
              <p className="text-green-blue font-lato font-normal text-base text-left">
                Question {index + 1}: {question.question}
              </p>
              <p>Réponse(s) sélectionnée(s): {answers[index + 1].join(", ")}</p>
            </li>
          ))}
        </ul>

        <div className="flex justify-end pt-4">
          <Link to="/">
            <button className="flex items-center gap-2 bg-green-blue text-white p-3 rounded">Recommencer le quiz</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Result;
