import React from "react";
import ContactInfo from "../About/ContactForm";
import Goals from "./Goals";
import Price from "./Price";
import Stepper from "./Stepper";
import Title from "./Title";

function TrainingDetails() {
  return (
    <div>
      <Title />
      <Goals />
      <Stepper />
      <Price />
      <ContactInfo />
    </div>
  );
}

export default TrainingDetails;
