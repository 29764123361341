import React, { useState } from "react";
import heartIcon from "../../medias/heart_about.svg";
import targetIcon from "../../medias/target_about_icon.svg";
import sunIcon from "../../medias/sun_about_icon.svg";
import exploreIcon from "../../medias/explore_about_icon.svg";
import binocularIcon from "../../medias/binocular_about_icon.svg";
import messageIcon from "../../medias/message_about_icon.svg";

// Flèches
import up from "../../medias/arrow_up.svg";
import down from "../../medias/arrow_down.svg";

function Bonus() {
  const [openSection, setOpenSection] = useState(null);

  const bonus = [
    {
      title: "Être humain et savoir s’amuser",
      text: "On est là pour faire des choses sérieuses, mais sans se prendre trop au sérieux !",
      icon: heartIcon,
    },
    {
      title: "Un accompagnement personnalisé",
      text: "Chaque personne est unique et mérite donc des conseils personnalisés.",
      icon: targetIcon,
    },
    {
      title: "Apprendre à se connaitre",
      text: "Mon but est d’attiser votre curiosité et de vous apprendre à écouter les signes autour de vous.",
      icon: sunIcon,
    },
    {
      title: "Être proche de la réalité terrain",
      text: "Pas de grands discours, pas de méthode toute faite, apprendre en appliquant c’est la clef de la réussite !",
      icon: binocularIcon,
    },
    {
      title: "Créer des liens",
      text: "Je suis là pour vous aider à créer des liens et à faire les bonnes connexions.",
      icon: messageIcon,
    },
    {
      title: "Des conseils applicables à vie",
      text: "La vie est pleine d’obstacles et il faut juste les bons réflexes pour pouvoir atteindre ses objectifs.",
      icon: exploreIcon,
    },
  ];

  const toggleSection = (index) => {
    if (openSection === index) {
      setOpenSection(null); // Fermer la section si elle est déjà ouverte
    } else {
      setOpenSection(index); // Ouvrir la nouvelle section
    }
  };

  return (
    <div className="flex flex-col items-center my-[2.5rem] md:my-20 md:mx-[6.25rem]">
      <h4 className="text-center text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal pb-[2rem] md:pb-8">
        Travailler avec moi, c'est...
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:mx-10 mx-4 gap-8">
        {bonus.map((bonus, index) => (
          <section
            key={index}
            id="bonus_description"
            className={`p-4 bg-white rounded-lg shadow-lg w-full transition-all duration-300 ${openSection === index ? 'bg-gray-50' : 'bg-white'}`}
            style={{
              minHeight: "70px",
            }}
          >
            {/* En-tête de chaque section */}
            <div
              className="flex items-center md:mb-4 cursor-pointer"
              onClick={() => toggleSection(index)}
            >
              <img src={bonus.icon} alt={bonus.title} className="mr-4 max-w-full" />
              <h3 className="text-oceanH1 font-yesevaOne md:text-2xl pr-2 font-normal leading-10 tracking-normal">
                {bonus.title}
              </h3>
              {/* Flèche de basculement */}
              <span className="ml-auto transition-transform duration-300 ease-in-out">
                <img
                  src={openSection === index ? up : down}
                  alt="Toggle Arrow"
                  className={`${openSection === index ? 'rotate-180' : 'rotate-0'}`} // Rotation de la flèche
                />
              </span>
            </div>

            {/* Texte de la section */}
            <div
              className={`transition-all duration-700 ease-in-out overflow-hidden ${openSection === index ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="ml-[3rem] transition-transform duration-500 ease-in-out transform">
                <p className="text-justify text-[#4B708A] mb-2">
                  {bonus.text}
                </p>
              </div>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}

export default Bonus;
