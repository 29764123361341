import React from "react";

function Price() {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-camel py-[2.5rem] px-[1rem] md:py-[5rem] md:px-[6.25rem]">
      <h3 className="text-oceanH1  font-yesevaOne text-4xl font-normal leading-10 tracking-normal">
        {" "}
        Prix : <b className="text-turquoise">900€</b>{" "}
      </h3>
      <h4 className="text-darkBlue  text-center  text-base  pt-[1rem] md:pt-[1rem] font-lato ">
        {" "}
        <b> Paiement : </b> le paiement se fait comptant ou 3 fois sans frais <br/>
        <b> Garantie de satisfaction : </b> si vous n’êtes pas satisfait après 3
        séances, je vous rembourse les séances non encore réalisées.
      </h4>
    </div>
  );
}

export default Price;
