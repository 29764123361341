import { useState } from "react";
import checkArrow from "../../medias/check.svg"; // Ajoutez votre propre chemin vers les images
import moduleImage from "../../medias/module_programm.png"; // Remplacez par l'image correcte

const ModuleProgram = () => {
  const [activeModule, setActiveModule] = useState(1);

  // Contenu des modules
  const moduleContent = {
    1: {
      title:
        "Explorer la multipotentialité, renouer avec soi-même et révéler son unicité",
      items: [
        {
          title: "Comprendre la multipotentialité",
          description:
            "Découvrez comment la multipotentialité peut être une force dans votre vie personnelle et professionnelle, tout en apprenant les stratégies essentielles pour surmonter les défis qui l'accompagnent.",
        },
        {
          title: "Se reconnecter à soi-même",
          description:
            "Apprenez à vous recentrer sur vous-même, à écouter vos véritables aspirations et à renforcer le lien avec votre intuition profonde.",
        },
        {
          title: "Identifier ce qui vous rend unique",
          description:
            "Révélez les qualités, les talents et les expériences qui vous distinguent, afin de les valoriser pleinement et d'accélérer votre épanouissement personnel et professionnel.",
        },
      ],
    },
    2: {
      title:
        "Explorer les possibles pour définir ses rêves et son avenir professionnel",
      items: [
        {
          title: "Explorer les possibles",
          description:
            "Ouvrez-vous à de nouvelles perspectives en découvrant les multiples voies qui s'offrent à vous.",
        },
        {
          title: "Définir ce qui est important pour soi",
          description:
            "Identifiez vos valeurs et priorités essentielles pour aligner vos choix de vie avec ce qui compte vraiment pour vous.",
        },
        {
          title: "Définir ses rêves et son avenir",
          description:
            "Donnez forme à vos rêves et aspirations à travers la clarification de votre vision.",
        },
        {
          title: "Exit, les excuses et les idées reçues",
          description:
            "Libérez-vous des excuses et des croyances limitantes pour embrasser pleinement votre potentiel et avancer avec confiance vers vos objectifs.",
        },
      ],
    },
    3: {
      title:
        "Mettre en place les stratégies de réussite durables pour équilibrer ses passions et sa carrière",
      items: [
        {
          title: "Mettre en place un plan d’action",
          description:
            "Définissez vos objectifs et élaborez un plan d’action concret qui vous permettra d’atteindre un équilibre harmonieux entre vos passions et aspirations professionnelles.",
        },
        {
          title: "Découvrir les stratégies pour une réussite durable",
          description:
            "Découvrez des approches éprouvées pour construire un succès durable, en intégrant des habitudes positives qui soutiennent votre croissance personnelle et professionnelle.",
        },
        {
          title: "Affronter et surmonter ses peurs",
          description:
            "Apprenez à reconnaître vos peurs et mettez en place des outils et des techniques pour les surmonter avec courage.",
        },
        {
          title: "Apprendre à se valoriser",
          description:
            "Découvrez des techniques pour valoriser qui vous êtes et vous affirmer pleinement dans vos relations personnelles et professionnelles.",
        },
      ],
    },
  };

  return (
    <div className="bg-white py-6 md:py-12 px-4 lg:px-20">
      <h2 className="text-[#00205b] font-yesevaOne text-[40px] font-bold mb-6">
        Le programme de la formation
      </h2>

      {/* Boutons des modules */}
      <div className="flex md:justify-start justify-center space-x-4 mb-8">
        <button
          onClick={() => setActiveModule(1)}
          className={`px-4 py-2 rounded-full text-[18px] font-bold ${
            activeModule === 1
              ? "bg-[#09929d] text-white"
              : "bg-[#c4fbff] text-[#09929d]" // texte vert foncé pour les modules non sélectionnés
          }`}
        >
          Module 1
        </button>
        <button
          onClick={() => setActiveModule(2)}
          className={`px-4 py-2 rounded-full text-[18px] font-bold ${
            activeModule === 2
              ? "bg-[#09929d] text-white"
              : "bg-[#c4fbff] text-[#09929d]" // texte vert foncé pour les modules non sélectionnés
          }`}
        >
          Module 2
        </button>
        <button
          onClick={() => setActiveModule(3)}
          className={`px-4 py-2 rounded-full text-[18px] font-bold ${
            activeModule === 3
              ? "bg-[#09929d] text-white"
              : "bg-[#c4fbff] text-[#09929d]" // texte vert foncé pour les modules non sélectionnés
          }`}
        >
          Module 3
        </button>
      </div>

      {/* Contenu des modules */}
      <div className="w-fit flex flex-col lg:flex-row items-center lg:items-start">
        <div className="w-full lg:w-1/2 pr-0 lg:pr-12">
          <h3 className="text-[#00205b] text-[24px] font-bold mb-6">
            {moduleContent[activeModule].title}
          </h3>
          <ul className="text-[#00205b] text-[18px] font-light leading-[28px] space-y-4">
            {moduleContent[activeModule].items.map((item, index) => (
              <li key={index} className="flex items-start">
                <img
                  src={checkArrow}
                  alt="check"
                  className="mr-6 mt-1"
                  style={{ width: "20px", height: "20px" }} // Taille fixe, proportionnelle
                />
                <span className="text-darkBlue text-base mb-4 leading-7">
                  <strong className="font-bold text-[#00205b]">
                    {item.title}
                  </strong>{" "}
                  : {item.description}
                </span>
              </li>
            ))}
          </ul>
          <a
            href="/contactez-moi"
            className="w-fit block rounded-md bg-[#09929d] mt-6 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-turquoisefonce focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Je suis prêt(e) à commencer !
          </a>
        </div>

        {/* Section de l'image, centrée horizontalement et verticalement */}
        <div className="w-full lg:w-1/2 mt-12 lg:mt-0 flex justify-center items-center">
          <img
            src={moduleImage}
            alt="Module Preview"
            className="w-[80%] lg:w-auto mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default ModuleProgram;
