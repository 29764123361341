import React from "react";
import pictureform from "../../medias/picture_form.png";

import { Link } from "react-router-dom";


function Form() {

  const form = [
    {
      title:
        "Commençons à faire connaissance et prenons rendez-vous ensemble !",
      text: "Avant de prendre rendez-vous, je t’invite à compléter ce quiz pour m’en dire un peu plus sur tes besoins, attentes, et sur ce qui te motive tout simplement ! Il n’y a pas de bonnes ou de mauvaises réponses. Plus les réponses de correspondent, plus mon coaching sera personnalisé à tes besoins. \n\n A la fin du questionnaire, tu pourras prendre rendez-vous avec moi en ligne ou en face à face directement dans un agenda. A noter : je me déplace que dans les Landes et le Pays-Basque pour les rendez-vous en face à face. \n\n Pendant ce premier rendez-vous, nous explorerons ensemble ce que tu recherches afin que je puisse ensuite te proposer un plan d’action sur-mesure qui va te permettre de te découvrir et de trouver un job qui te fais vibrer !\n\n J’ai hâte de commencer notre nouvelle collaboration et d’accomplir de nouveaux challenges ensemble 😁.\n\n",
      button: {
        text: "Commencez le quizz !",
        url: "",
      },
    },
  ];

  return (
    <div id="question_form" className="">
    {form.map((form, index) => (
      <div
        id="question_form_homepage"
        className="w-full fixed flex"
        key={index}
      >
        <div
          id="picture_side"
          className="bg-background_picture_form bg-cover bg-center w-[45%] min-h-[100vh] flex items-center justify-center"
        >
          <img
            src={pictureform}
            alt=""
            className="w-1/4 object-cover"
          />
        </div>
        <div
          id="texte_side"
          className="bg-white w-[55%] min-h-[100vh] flex-col px-[6rem] gap-98 flex items-start justify-center"
        >
          <p className="w-full sticky top-0 left-0 text-ocean font-lato font-extrabold text-2xl">
            {form.title}
          </p>
          <p className="text-grey text-left font-lato font-light text-base leading-normal tracking-tight py-[2rem]" style={{ whiteSpace: "pre-line" }}>
            {form.text}
          </p>
          <Link to='/questions'>
          <button className="bg-green-blue p-3 text-white w-full">
            {form.button.text}
          </button>
          </Link>
        </div>
      </div>
    ))}
  </div>
  );
}

export default Form;
