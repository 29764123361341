import React from 'react';

function AccordionItem({ title, interests }) {
  return (
    <section className="flex flex-col justify-center items-center w-full py-[2.5rem] px-[1rem] lg:py-[5rem] lg:px-[6.25rem] bg-[#fdedde]">
      <div className="flex items-center justify-center flex-col">
        <h4 className="text-center text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal pb-[2rem]">
          {title}
        </h4>
        <div className="flex flex-wrap justify-center gap-4 max-w-screen-lg">
          {interests.map((interest, index) => (
            <span
              key={index}
              className="bg-[#c4fbff] text-[#09929d] font-lato text-base font-normal py-2 px-4 rounded-full"
            >
              {interest}
            </span>
          ))}
        </div>
      </div>
    </section>
  );
}

function Funfact() {
  const descriptions = [
    {
      title: "Mes passions & intérêts",
      interests: [
        "Pédagogie",
        "Développement professionnel",
        "Développement personnel",
        "Web design",
        "Recherche utilisateur",
        "Innovation",
        "Animer des ateliers",
        "Art",
        "Voyages",
        "Nature",
        "Animaux"
      ],
    },
  ];

  return (
    <div>
      {descriptions.map((description, index) => (
        <AccordionItem key={index} {...description} />
      ))}
    </div>
  );
}

export default Funfact;
