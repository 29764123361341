import React from "react";

import Description from "./Description";
import Service from "../Services/Service";
import ContactBanner from "../ContactBanner";

function Services() {
  return (
    <div className="main_section">
      <Service />
      <Description />
      <ContactBanner />
    </div>
  );
}

export default Services;
