import React from "react";

function Bonus() {
  const bonus = [
    // {
    //   id: "quiz",
    //   title: "Quiz : quel type de multipotentiel es-tu ?",
    //   text: "Un quiz en ligne pour te permettre d’identifier quel type de multipotentiel(le) tu es, pour mieux te comprendre et obtenir des conseils personnalisés.",
    //   telecharger: "Faire le quiz !",
    //   url: "https://drive.google.com/file/d/1aOd_se316Zx4099mOOV_FnVmoO6TG_JZ/view",
    //   pdf: "/pdfs/Joy Blast - Bilan quotidien.pdf",
    // },
    {
      title: "Cahier pour faire un bilan de mi-année sur tes objectifs",
      text: "Un PDF pour que tu puisses faire un bilan de mi-année sur tes objectifs. Tu pourras l’utiliser pour faire le point sur ta vision, tes réalisations, tes progrès, tes défis et réajuster tes objectifs si nécessaire.",
      telecharger: "Je le veux !",
      url: "https://joyblast-coaching.systeme.io/c745a7cd-b14c06ea",
      pdf: "/pdfs/Joy Blast - Bilan quotidien.pdf",
    },
    {
      title: "50 idées pour se reconnecter avec soi-même",
      text: "Une liste de méthodes pour se reconnecter avec toi-même et maximiser les chances pour que tu découvres une voie professionnelle qui te fera vibrer !",
      telecharger: "Je les veux !",
      url: "https://drive.google.com/file/d/1aOd_se316Zx4099mOOV_FnVmoO6TG_JZ/view",
      pdf: "/pdfs/Joy Blast - Bilan quotidien.pdf",
    },
  ];

  return (
    <div>
      <div
  id="bonus_section"
  className="relative w-full h-full bg-white md:bg-background_bonus md:bg-center-top md:bg-no-repeat md:z-30 pt-10 flex flex-col items-center pb-4 md:pb-20"
  style={{
    marginTop: '-600px',
    paddingTop: '600px',
    backgroundSize: 'cover', // Assure que le fond couvre toute la largeur disponible
  }}
>
        <h4 className=" md:mb-5 mt-8 md:mt-20 text-oceanH1 font-yesevaOne font-600 text-3xl md:text-4xl">
          Bonus gratuits
        </h4>

        {bonus.map((bonus, index) => (
          <section
            key={index}
            id="bonus_description"
            className={`transition duration-300 rouded-xl ease-in-out transform hover:scale-105 w-[90%] lg:w-[60%] p-8 my-[0.5rem] items-start gap-4 rounded-lg drop-shadow-2xl ${
              bonus.id === "quiz"
                ? "bg-gradient-to-r from-[#ff9d0c] via-[#ffac30] to-[#ffba52] text-white"
                : "bg-white"
            }`}
          >
            <h3
              className={`mb-[1rem] font-lato text-2xl font-extrabold leading-12 tracking-wide ${
                bonus.id === "quiz" ? "text-white" : "text-[#012d72]"
              }`}
            >
              {bonus.title}
            </h3>
            <p className={`w-[100%] my-[1rem] leading-7 ${bonus.id === "quiz" ? "text-white" : "text-darkBlue"}`}>
              {bonus.text}
            </p>
            <div className="lg:flex lg:w-full justify-end lg:flex-end items-end">
              <a
                href={bonus.url}
                download={bonus.pdf}
                className={`p-[0.65rem 1rem] lg:mt-[1rem] h-10 my-[0.5rem] lg:w-1/6 w-full ${
                  bonus.id === "quiz"
                    ? "bg-ff9b04 text-white border-white "
                    : "bg-white text-dark"
                } border-2 border-dark rounded text-base font-bold font-sans flex justify-center items-center`}
                target="_blank"
                rel="noreferrer"
              >
                {bonus.telecharger}
              </a>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}

export default Bonus;
