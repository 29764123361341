import React from "react";
import image1 from "../medias/Comment_trouver_ses_passions.jpg";
import image2 from "../medias/Epanouir_professionnellement.jpg";
import image3 from "../medias/Le_biais_de_confirmation.jpg";
import image4 from "../medias/Tes_modeles_ont_commencees_de_zero.jpg";

const Footer = () => {
  return (
    <footer
      className="shadow-2xl w-full bg-white text-oceanH1 py-8 bottom-0 sm:flex sm:flex-col align-center items-center"
      style={{
        boxShadow: "0 -10px 20px rgba(0, 0, 0, 0.1)", // Ajustez les valeurs selon vos besoins
      }}
    >
      <div className="w-full lg:p-8 lg:inline-flex lg:px-16 align-start items-start justify-center">
        <div
          className="lg:w-2/3 flex-wrap lg:text-left text-center"
          id="post_instagram"
        >
          <h3 className="lg:mb-0 mb-4 lg:ml-4 font-bold">
            Retrouve-moi sur Instagram :
          </h3>
          <ul className="w-full flex flex-wrap items-start align-center justify-center lg:align-start lg:justify-start">
            <li className="max-w-[33%] lg:w-[7rem] mr-2 mb-2 lg:ml-4 lg:my-4">
              <a
                href="https://www.instagram.com/joyblast.coaching/"
                className="w-full block rounded overflow-hidden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-full h-1/2 object-cover rounded"
                  src={image1}
                  alt="Comment trouver ses passions"
                />
              </a>
            </li>
            <li className="max-w-[33%] lg:w-[7rem] mr-2 mb-2 lg:ml-4 lg:my-4">
              <a
                href="https://www.instagram.com/joyblast.coaching/"
                className="w-full block rounded overflow-hidden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-full h-1/2 object-cover rounded"
                  src={image2}
                  alt="Epanouir professionnellement"
                />
              </a>
            </li>
            <li className="max-w-[33%] lg:w-[7rem] mr-2 mb-2 lg:ml-4 lg:my-4">
              <a
                href="https://www.instagram.com/joyblast.coaching/"
                className="w-full block rounded overflow-hidden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-full h-1/2 object-cover rounded"
                  src={image3}
                  alt="Le biais de confirmation"
                />
              </a>
            </li>
            <li className="max-w-[33%] lg:w-[7rem] mr-2 mb-2 lg:ml-4 lg:my-4">
              <a
                href="https://www.instagram.com/joyblast.coaching/"
                className="w-full block rounded overflow-hidden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-full h-1/2 object-cover rounded"
                  src={image4}
                  alt="Tes modèles ont commencées de zéro"
                />
              </a>
            </li>
          </ul>
        </div>
        <div
          className="w-fit lg:w-1/3 ml-12 lg:my-0 my-8 container flex flex-col items-start"
          id="lien_important"
        >
          <h3 className="font-bold text-left"> Autres : </h3>

          <ul className="w-full flex flex-col sm:flex-wrap">
            <li className="py-2">
              <a
                href="/conditions_generales"
                className="hover:text-[#09929D] font-lato font-extralight"
              >
                Conditions Générales de Vente
              </a>
            </li>

            <li className="pb-2">
              <a
                href="/politique_de_confidentialite"
                className="hover:text-[#09929D] font-lato font-extralight"
              >
                Politique de confidentialité{" "}
              </a>
            </li>

            <li className="pb-2">
              <a
                href="/mentions_legales"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#09929D] font-lato font-extralight"
              >
                Mentions légales
              </a>
            </li>
            {/* <li className="pb-2">
              <a
                href="/mentions_legales"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#09929D] font-lato font-extralight"
              >
                Accessibilité
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="text-sm text-center px-6">
        <p>&copy; 2024 Margot Batoufflet. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
