import communautePicture from "../../medias/communaute_picture_bilan.jpg";
import checkArrow from "../../medias/check.svg";

const BilanCompetence = () => {
  return (
    <div className="bg-white py-12 px-4 lg:px-20">
      {/* Première section */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start">
        <div className="w-full lg:w-1/2 pr-0 lg:pr-12">
          <h2 className="text-[#00205b] font-yesevaOne text-[30px] font-bold mb-6">
          Le bilan de compétence c’est ...          </h2>
          <p className="text-[#00205b] text-[18px] font-bold leading-[28px] mb-6">
          Un bilan en présentiel ou 100% à distance avec :          </p>
          <ul className="text-[#00205b] text-[18px] font-extralight leading-[28px] space-y-4">
            <li className="flex items-center ">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">Des supports</strong> :
              des ressources et des outils que vous pourrez utiliser à vie à chaque transition.
              </span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">Des exercices pratiques : </strong>
                 des activités et des exercices concrets pour passer à l’action et appliquer ce que vous apprenez.</span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">Un espace collaboratif personnalisé :</strong>
                pour rendre l’accompagnement plus engageant et dynamique à distance.</span>
            </li>
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">Un espace de discussion : </strong>
                pour que je puisse répondre à vos questions et vous aiguiller tout le long de l’accompagnement.
              </span>
            </li>
          </ul>
          <h3 className="text-[#00205b] text-[18px] font-bold mt-8 mb-4">
            Et pour aller plus loin :
          </h3>
          <ul className="text-[#00205b] text-[18px] font-extralight leading-[28px] space-y-4">
            <li className="flex items-center">
              <img src={checkArrow} alt="check" className="mr-6" />
              <span>
                <strong className="font-bold">
                1 séance de suivi
                </strong>{" "}
                : après le bilan de compétences pour voir si ton projet continu d’aller dans la bonne direction et te donner un coup de pouce supplémentaire si besoin :) !
              </span>
            </li>
            
          </ul>
        </div>
        <div className="w-full lg:w-1/2 mt-12 lg:mt-0 flex justify-center lg:justify-end">
          <img
            src={communautePicture}
            alt="Supports de cours"
            className="w-[80%] lg:w-auto"
          />
        </div>
      </div>


      <button className="mt-8 bg-[#09929d] text-white py-3 px-8 rounded-md hover:bg-[#005e5b] transition-colors font-lato">
          Je suis prêt(e) à commencer !{" "}
        </button>
      
    </div>
  );
};

export default BilanCompetence;
