import React from "react";
import maximilien from '../../medias/avis_max_picture.png';
import morgane from '../../medias/morgane.png';


function Avis() {
  const avis = [
    {
      id: 1,
      name: "Maximilien",
      image: maximilien,
      text: "Merci beaucoup à Margot qui m’a permis de prendre du recul sur mes valeurs et sur ce qui est finalement essentiel pour mon épanouissement professionnel. De très bons raisonnements et des questions pertinentes.",
    job: 'Développeur web'
    },
    {
      id: 2,
      name: "Morgane",
      image: morgane,
      text: "Margot a su, avec dextérité et justesse m’amener tout droit vers ce que j’aime le plus faire dans mon métier. Grâce à elle, j’ai un plan clair et concret de ce que j’ai à mettre en place pour kiffer mon job ! Merci encore !",
    job: 'Cartomancienne'
    },
  ];

  return (
    <div className="w-full bg-[#002955] py-12 flex flex-col items-center">
      <h3 className="text-[#00e0f0] font-yesevaOne pt-4 text-3xl md:text-4xl ">
        Les témoignages
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-5xl p-8">
        {avis.map((item) => (
          <div
            key={item.id}
            className="w-fit bg-white rounded-lg shadow-lg py-8   flex flex-col items-center text-center"
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-24 h-24 rounded-full object-cover"
            />
            <p className="text-darkBlue text-base mb-4 w-3/4 leading-7">{item.text}</p>
            <p className="text-turquoise text-lg font-semibold">{item.name}</p>
            <p className="text-turquoise text-sm font-semibold">{item.job}</p>

          </div>
        ))}
      </div>
    </div>
  );
}

export default Avis;



