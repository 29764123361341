import React from 'react';

const MentionsLegales = () => {
  return (
    <div className="w-full font-lato p-16 bg-camel  flex flex-col align-center items-center justify-center text-center">
      <h1 className="text-3xl font-bold mb-6 text-oceanH1">Mentions légales</h1>

      <p className="mb-6">
        Conformément à la Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique (LCEN), il est porté à la connaissance des utilisateurs du site internet accessible à l’adresse : <a href="https://joyblast-coaching.com" className="text-blue-500 underline">https://joyblast-coaching.com</a> (ci-après désigné le « Site ») dans les présentes mentions légales.
      </p>

      <p className="mb-6">
        Mises à jour : 27/01/2024
      </p>

      <h2 className="text-2xl font-bold mb-4 text-oceanH1 pt-8">I. Identité de l’entreprise</h2>

      <p className="mb-2">Nom commercial de l’entreprise : MARGOT BATOUFFLET EI</p>
      <p className="mb-2">Chef d’entreprise : MARGOT BATOUFFLET</p>
      <p className="mb-2">Forme juridique : Micro-entreprise</p>
      <p className="mb-2">N° SIRET : 923 343 842 00012</p>
      <p className="mb-2">Domiciliation : 6 rue de mounsempès, 40230 Saint-Vincent-de-Tyrosse, France</p>
      <p className="mb-2">Courriel : <a href="mailto:joyblast.consulting@gmail.com" className="text-blue-500 underline">joyblast.consulting@gmail.com</a></p>
      <p className="mb-2">N° RCS ou RMA : aucun</p>
      <p className="mb-2">TVA : non applicable selon l’article 293 B du C.G.I</p>
      <p className="mb-2">Activité non règlementée, non soumise à autorisation</p>

      <h2 className="text-2xl font-bold mb-4 text-oceanH1 pt-8">II. Identité de l’hébergeur</h2>

      <p className="mb-2">Site : <a href="https://joyblast-coaching.com" className="text-blue-500 underline">https://joyblast-coaching.com</a></p>
      <p className="mb-2">Nom ou dénomination sociale de l’hébergeur : O2Switch</p>
      <p className="mb-2">Adresse postale : Chemin des Pardiaux - 63000 Clermont-Ferrand</p>
      <p className="mb-2">N° de téléphone : <a href="tel:0444446040" className="text-blue-500 underline">04 44 44 60 40</a></p>
      <p className="mb-2">Courriel : <a href="mailto:support@o2switch.fr" className="text-blue-500 underline">support@o2switch.fr</a></p>

      <h2 className="text-2xl font-bold mb-4 text-oceanH1 pt-8">III. Responsable de la publication</h2>

      <p className="mb-2">Identité : Mme MARGOT BATOUFFLET</p>
      <p className="mb-2">Poste : consultante en transition professionnelle</p>
      <p className="mb-2">Courriel : <a href="mailto:joyblast.consulting@gmail.com" className="text-blue-500 underline">joyblast.consulting@gmail.com</a></p>
    </div>
  );
};

export default MentionsLegales;
