import React, { useState } from "react";
// import margotImage from "../../medias/margot_ordinateur.png";

// Flèches
import up from "../../medias/arrow_up.svg";
import down from "../../medias/arrow_down.svg";

import margotImage from "../../medias/margot_ordinateur.png";

const PricingFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(index);
    }
  };

  return (
    <div className="bg-white">
      {/* Section : Qui prend soin des participants ? */}

      {/* Section : Je rejoins la Multi Adventure */}
      {/* <section className="py-12 px-4 lg:px-20 text-center">
        <h2 className="text-[#00205b] font-yesevaOne text-[30px] font-bold mb-12">
          Je rejoins la Multi Adventure
        </h2>

        <div className="flex flex-col lg:flex-row justify-center space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="bg-[#fff0df] rounded-md py-8 px-6 shadow-lg w-full lg:w-1/3">
          <p className="font-lato text-[#002955] text-[18px] font-[400] mb-2">
          Paiement en 3 fois
            </p>
            <p className="text-[#00205b] text-[30px] font-bold">332€ / mois</p>
            <button className="mt-4 px-4 py-2  bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je suis convaincu(e) !
            </button>
          </div>

          <div className="bg-[#ffe2c8] rounded-md py-8 px-6 shadow-lg w-full lg:w-1/3">
            <p className="font-lato text-[#002955] text-[18px] font-[400] mb-2">
              Paiement en 1 fois
            </p>
            <p className="text-[#00205b] text-[30px] font-bold">996€</p>
            <button className="mt-4 px-4 py-2 bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je passe à l'action !
            </button>
          </div>
        </div>
      </section> */}

      <section className="px-4 bg-[#fff0df]  lg:px-10 flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-center lg:mt-0 lg:self-end order-2 lg:order-1">
          <img
            src={margotImage}
            alt="Margot"
            className="max-w-[80%] lg:max-w-[60%] rounded-md"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:pr-12 order-2 lg:order-1 md:my-0 my-8">
          <h3 className="text-[#00205b] font-yesevaOne md:mt-10 text-[30px] font-bold md:mb-0 mb-8 px-[1rem] md:px-0 ">
          Qui prend soin des participants ?{" "}
          </h3>
          <p className="px-[1rem] md:px-0 lg:w-[100%] lg:pt-8 leading-7 text-oceanH2 mb-6">
            Je suis coach professionnelle chez Joy Blast. Ma mission est
            d’accompagner les multipotentiels à explorer et exploiter leur
            potentiel pour transformer leur carrière. Cette formation
            spécialement conçue pour coacher les multipotentiels allie des
            méthodes de coaching professionnel, de développement personnel et de
            mentorat pour vous aider à atteindre vos objectifs. Je vous y guide
            pas à pas avec les stratégies de réussite pour exploiter pleinement
            vos compétences multiples, trouver votre voie en équilibrant vos
            passions et réaliser vos projets les plus ambitieux.
          </p>
          <a
            href="/a_propos"
            className="block rounded-md bg-[#00205b] text-white md:mb-10 mt-6 px-4 py-2 font-semibold shadow-md hover:bg-[#001a47] w-fit"
          >
            En savoir plus sur Margot{" "}
          </a>
        </div>
      </section>

      {/* Section : FAQ */}
      <section
        className="py-12 px-4 lg:px-20"
        style={{
          background: "linear-gradient(to right, #ffa115, #ffb74a)",
        }}
      >
        <h2 className="text-white font-yesevaOne text-[30px] font-bold text-center mb-8">
          FAQ - Questions fréquentes
        </h2>

        <div className="max-w-4xl mx-auto">
          {/* FAQ Items */}
          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(1)}
            >
              <span className="text-[#00205b] font-semibold">
                Est-ce finançable avec mon compte CPF ?
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 1 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 1 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Non, malheureusement la Multi Adventure n'est pas finançable
                avec le CPF ou autres aides (FIFPL, OPCO, etc).
              </div>
            )}
          </div>

          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(2)}
            >
              <span className="text-[#00205b] font-semibold">
                Est-ce que la Multi Adventure est 100% à distance ?
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 2 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 2 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Oui, la Multi Adventure est 100% à distance, mais si des
                personnes de la formation ou de la communauté sont dans ta
                région, libre à toi de les rencontrer !
              </div>
            )}
          </div>

          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(3)}
            >
              <span className="text-[#00205b] font-semibold">
                Les places sont limitées pour la liste d’attente ?
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 3 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 3 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Non, pas de limite ! Vous pouvez vous inscrire sans soucis et ça
                ne vous engage à rien du tout ! 🙂 Vous serez notifié dès que la
                formation sera disponible.
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <button className="mt-4 px-4 py-2  bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Convaincu(e) ? Rejoins la liste d'attente
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingFAQ;
