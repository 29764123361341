function ContactBanner() {
  const contact = [
    {
      title: "Envie que je t'accompagne ?",
      subtitle: "Lance-toi, mon contact est à quelques clics !",
      button: "Contacter Margot",
    },
  ];

  return (
    <>
      {contact.map((contact, index) => (
        <div
          key={index}
          className="bg-oceanH1 isolate bg-ocean bg-background_opinion bg-cover bg-no-repeat text-center px-[1rem] lg:px-[6.25rem] py-[2.5rem] lg:py-[5rem] flex flex-col justify-center align-center items-center"
        >
          <h4 className="text-[#2EE0F0] my-4 font-yesevaOne text-4xl">
            {" "}
            {contact.title}{" "}
          </h4>
          <h5 className="text-[#2EE0F0] text-2xl font-extralight">
            {contact.subtitle}
          </h5>
          <a href='/contactez-moi' className="block mt-[2rem] rounded-md bg-green-blue px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-turquoisefonce focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {contact.button}
          </a>
        </div>
      ))}
    </>
  );
}

export default ContactBanner;
