import React, { useState } from "react";
import margotImage from "../../medias/margot_ordinateur.png";

// Flèches
import up from "../../medias/arrow_up.svg";
import down from "../../medias/arrow_down.svg";

const PricingFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(index);
    }
  };

  return (
    <div className="bg-white">
      {/* Section : Qui prend soin des participants ? */}
      <section className="px-4 bg-[#fff0df]  lg:px-10 flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-center lg:mt-0 lg:self-end order-2 lg:order-1">
          <img
            src={margotImage}
            alt="Margot"
            className="max-w-[80%] lg:max-w-[60%] rounded-md"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:pr-12 order-2 lg:order-1">
          <h3 className="text-[#00205b] font-yesevaOne md:mt-10 text-[30px] font-bold ">
            Qui vous accompagne ?
          </h3>
          <p className="px-[1rem] md:px-0 lg:w-[100%] lg:pt-8 leading-7 text-oceanH2">
            Je suis Margot, coach professionnelle chez Joy Blast. Ma mission est
            d’accompagner les multipotentiels à explorer et exploiter leur
            potentiel pour transformer leur carrière. Le bilan de compétences
            est un accompagnement individuel et sur-mesure où j’allie des
            méthodes de coaching professionnel, de développement personnel et de
            conseil. Je vous guide pas à pas pour analyser vos compétences,
            expériences, passions, intérêts et pour définir ensemble plusieurs
            trajectoires professionnelles qui fera sens pour vous.
          </p>
          <a
            href="/a_propos"
            className="block rounded-md bg-[#00205b] text-white md:mb-10 mt-6 px-4 py-2 font-semibold shadow-md hover:bg-[#001a47] w-fit"
          >
            En savoir plus sur Margot{" "}
          </a>
        </div>
      </section>

      {/* Section : Je rejoins la Multi Adventure */}
      <section className="py-12 px-4 lg:px-20 text-center">
        <h2 className="text-[#00205b] font-yesevaOne text-[30px] font-bold mb-12">
          Je fais un bilan de compétences{" "}
        </h2>

        <div className="flex flex-col lg:flex-row justify-center space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="bg-[#fff0df] rounded-md py-8 px-6 shadow-lg w-full lg:w-1/3">
            <p className="font-lato text-[#002955] text-[18px] font-[400] mb-2">
              Paiement en 3 fois
            </p>
            <p className="text-[#00205b] text-[30px] font-bold">370€ / mois</p>
            <button className="mt-4 px-4 py-2  bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je suis convaincu(e) !
            </button>
          </div>

          <div className="bg-[#ffe2c8] rounded-md py-8 px-6 shadow-lg w-full lg:w-1/3">
            <p className="font-lato text-[#002955] text-[18px] font-[400] mb-2">
              Paiement en 1 fois
            </p>
            <p className="text-[#00205b] text-[30px] font-bold">1110€</p>
            <button className="mt-4 px-4 py-2 bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je passe à l'action !
            </button>
          </div>
        </div>
      </section>

      {/* Section : FAQ */}
      <section
        className="py-12 px-4 lg:px-20"
        style={{
          background: "linear-gradient(to right, #ffa115, #ffb74a)",
        }}
      >
        <h2 className="text-white font-yesevaOne text-[30px] font-bold text-center mb-8">
          FAQ - Questions fréquentes
        </h2>

        <div className="max-w-4xl mx-auto">
          {/* FAQ Items */}
          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(1)}
            >
              <span className="text-[#00205b] font-semibold">
                Est-ce finançable avec mon compte CPF ?
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 1 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 1 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Non, malheureusement la Multi Adventure n'est pas finançable
                avec le CPF ou autres aides (FIFPL, OPCO, etc).
              </div>
            )}
          </div>

          <div className="bg-white rounded-md shadow-lg mb-4">
            <button
              className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFAQ(2)}
            >
              <span className="text-[#00205b] font-semibold">
                Est-ce que le bilan de compétence est 100% à distance ou en
                présentiel ?{" "}
              </span>
              <span className="text-[#00205b]">
                <img src={openFAQ === 2 ? up : down} alt="arrow" />
              </span>
            </button>
            {openFAQ === 2 && (
              <div className="px-6 pb-4 text-[#00205b]">
                Tout dépend où vous habitez et aussi de votre préférence. Je
                suis basée dans le sud des Landes, je peux faire des bilans de
                compétences autour de chez moi et même dans le pays-basque. Si
                nous n’habitons pas à côté le format 100% à distance est tout à
                fait possible.
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <button className="mt-4 px-4 py-2  bg-[#09929d] text-white rounded-md font-semibold hover:bg-[#077b78]">
              Je veux faire le bilan de compétences{" "}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingFAQ;
