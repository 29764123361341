import React from 'react'
import HeroSection from './HeroSection'
// import Formation from './Formation'
// import Testimonial from './Testimonial'
import MultiAdventureComponent from './MultiAdventure'
// import Avis from './Avis'
// import ModuleProgram from './ModuleProgramm'
import PricingFAQ from './PricingFAQ'

function JoyCommunity() {
  return (
    <div>
        <HeroSection />
       {/* <Formation />
        <Testimonial /> */}
        <MultiAdventureComponent />
        {/* <Avis />
        <ModuleProgram /> */}
        <PricingFAQ />
    </div>
  )
}

export default JoyCommunity