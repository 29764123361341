import React from "react";

//icons
import possibilite from '../../medias/possibility_icon.svg';
import bonheur from '../../medias/bonheur_icon.svg';
import reussite from '../../medias/reussite_icon.svg'

const Formation = () => {
  return (
    <section className="bg-white md:py-16 py-8 px-8">
      {/* Titre principal */}
      <div className="text-center mb-12">
        <h2 className="text-[28px] lg:text-[36px] font-yesevaOne font-bold text-[#00205b] leading-[1.3]">
          Une formation créée pour les personnes{" "} <br/>
          <span className="text-[#f5a623]"> multipotentielles</span> qui souhaitent ...
        </h2>
      </div>

      {/* Cartes descriptives */}
      <div className="flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-6 mb-16">
        <div className="bg-white shadow-lg rounded-lg p-6 text-left w-full lg:w-fit border border-[#F5A724]">
          <p className="text-[16px] font-lato text-[#4d748f] leading-[1.6]">
            explorer leurs passions, intérêts et compétences  pour ensuite les valoriser.
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 text-left w-full lg:w-fit border border-[#F5A724]">
          <p className="text-[16px] text-[#4d748f] leading-[1.6] font-lato">
            avoir une vision plus claire de leurs objectifs et de leur avenir pour pouvoir s'épanouir pleinement.
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 text-left w-full lg:w-fit border border-[#F5A724]">
          <p className="text-[16px] text-[#4d748f] leading-[1.6] font-lato">
            naviguer plus sereinement dans leur vie en intégrant leurs multiples facettes.
          </p>
        </div>
      </div>

    {/* Section avec icônes */}
<div className="display flex flex-col items-center space-y-8">
  <div className="text-center mb-12">
    <h3 className="text-[26px] lg:text-[30px] font-yesevaOne font-bold text-[#00205b] leading-[1.2]">
      Juste imagine une vie avec plus de ...
    </h3>
  </div>

  <div className="flex flex-col lg:flex-row justify-center items-center space-y-8 lg:space-y-0 lg:space-x-12 w-full">
    {/* Possibilités */}
    <div className="text-center md:w-1/3">
      <div className="inline-block mb-4">
        <img alt='' src={possibilite} />
      </div>
      <h4 className="text-[18px] font-bold text-[#00205b]">Possibilités</h4>
      <p className="text-[15px] text-center text-[#4d748f] leading-[1.6] mt-2">
        Plus besoin de vous limiter à une seule voie qui vous empêche de vous épanouir avec vos multiples passions et intérêts.
      </p>
    </div>

    {/* Bonheur */}
    <div className="text-center md:w-1/3">
      <div className="inline-block mb-4">
        <img alt='' src={bonheur} />
      </div>
      <h4 className="text-[18px] font-bold text-[#00205b]">Bonheur</h4>
      <p className="text-[15px] text-[#4d748f] leading-[1.6] mt-2">
        Plus besoin de faire un métier qui ne te convient pas et d’attendre le week-end. Aime ce que tu fais chaque jour de ta vie !
      </p>
    </div>

    {/* Réussite */}
    <div className="text-center md:w-1/3">
      <div className="inline-block mb-4">
        <img alt='' src={reussite} />
      </div>
      <h4 className="text-[18px] font-bold text-[#00205b]">Réussite</h4>
      <p className="text-[15px] text-[#4d748f] leading-[1.6] mt-2">
        Avec une vision claire de votre avenir qui prend en compte votre multipotentialité, vous réussirez mieux dans tout ce que vous entreprenez.
      </p>
    </div>
  </div>

  {/* Bouton placé en bas */}
  <div className="text-center mt-8"> 
    <a href='/contactez-moi' className="w-fit block rounded-md bg-[#09929d] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-turquoisefonce focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      Je suis prêt(e) !
    </a>
  </div>
</div>

    </section>
  );
};

export default Formation;
