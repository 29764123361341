import React from "react";
// import PresentationImage from "../../medias/presentation_contact.svg";

function Presentation() {
  const presentation = [
    {
      title:
        "Commençons à faire connaissance et prenons rendez-vous ensemble !",
      text: "Avant de prendre rendez-vous, je t’invite à compléter ce quiz pour m’en dire un peu plus sur tes besoins, attentes, et sur ce qui te motive tout simplement ! Il n’y a pas de bonnes ou de mauvaises réponses. Plus les réponses correspondent, plus mon coaching sera personnalisé à tes besoins.",
      text2:
        "A la fin du questionnaire, tu pourras prendre rendez-vous avec moi en ligne ou en face à face directement dans un agenda. A noter : je me déplace que dans les Landes et le Pays-Basque pour les rendez-vous en face à face.",
      text3:
        "Pendant ce premier rendez-vous, nous explorerons ensemble ce que tu recherches afin que je puisse ensuite te proposer un plan d’action sur-mesure qui va te permettre de te découvrir et de trouver un job qui te fais vibrer !",
      text4:
        "J’ai hâte de commencer notre nouvelle collaboration et d’accomplir de nouveaux challenges ensemble 😁.",
      button: "Commencez le quizz !",
      link: "/questions",
      bonus:
        "Ps : un cours en ligne gratuit t’attends à la fin du questionnaire",
    },
  ];

  return (
    <div className="md:flex md:w-full">
      {/* Section pour l'image */}
      <div className="w-1/3 h-screen">
        {/* <img
          src={PresentationImage}
          alt="presentation_de_contact"
          className="w-full h-full object-cover"
        /> */}
      </div>

      {/* Section pour le texte */}
      <div className="w-2/3 flex flex-col justify-center items-center text-left px-26">
        <h3 className="text-oceanH1 font-lato font-extrabold text-2xl mb-8">
          {presentation[0].title}
        </h3>
        <p className="mb-6 text-darkBlue font-lato text-base font-light mx-24 text-justify  my-1  lg:p-0">
          {presentation[0].text}
        </p>

        <p className="mb-6 text-darkBlue font-lato text-base font-light  mx-24 text-justify  my-1  lg:p-0">
          {presentation[0].text2}
        </p>
        <p className="mb-6 text-darkBlue font-lato text-base font-light  mx-24 text-justify  my-1 lg:p-0">
          {presentation[0].text3}
        </p>
        <p className="mb-6 text-darkBlue font-lato text-base font-light mx-24 text-justify  my-1  lg:p-0">
          {presentation[0].text4}
        </p>
        <div className="">
          {" "}
          <a
            href="/questions"
            className="flex items-center gap-2 mt-6 bg-green-blue text-white p-3 rounded"
          >
            {presentation[0].button}
          </a>
        </div>

        {/* <p
          className="mb-8 text-darkBlue font-lato text-base font-normal  text-justify px-4 lg:p-0"
          href={presentation[0].link}
        >
          {presentation[0].bonus}
        </p> */}
      </div>
    </div>
  );
}

export default Presentation;
