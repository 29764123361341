import React from "react";
import firstProgramImage from "../../medias/bonus_image_journal.jpg";
import thirdProgramImage from "../../medias/50_idees_pour_se_reconnecter.jpg";
import ContactInfo from "../About/ContactForm";

function Bonus() {
  const programme = [
    {
      title: "Cahier pour bilan de mi-année sur tes objectifs",
      text: "Un PDF pour que tu puisses faire un bilan de mi-année sur tes objectifs. Tu pourras l’utiliser pour faire le point sur ta vision, tes réalisations, tes progrès, tes défis et réajuster tes objectifs si nécessaire.",
      imageUrl: firstProgramImage,
      button: "Je les veux !",
      url: "https://joyblast-coaching.systeme.io/c745a7cd-b14c06ea",
      pdf: "/pdfs/Joy Blast - 50 idées pour se reconnecter avec soi-même.pdf",
    },
    {
      title: "50 idées pour se reconnecter avec soi-même",
      text: "Une liste de méthodes pour se reconnecter avec toi-même et maximiser les chances pour que tu découvres une voie professionnelle qui te fera vibrer !",
      imageUrl: thirdProgramImage,
      button: "Je le veux !",
      url: "https://drive.google.com/file/d/1aOd_se316Zx4099mOOV_FnVmoO6TG_JZ/view",
      pdf: "/pdfs/Joy Blast - 50 idées pour se reconnecter avec soi-même.pdf",
    },
  ];

  return (
    <div className="bg-[#F9FAFC]">
      <h4 className="text-oceanH1 font-yesevaOne text-4xl font-normal leading-10 tracking-normal text-center pt-[2.5rem]  md:pt-[5rem]">
        Bonus gratuits
      </h4>

      <div
        id="bonus_section"
        className="w-full flex flex-col items-center md:my-[4rem]"
      >
        {programme.map((document, index) => (
          <div
            className="rounded-xl md:w-[80%] mx-6 bg-white shadow-inner my-[2rem] flex flex-col lg:shadow-lg xl:shadow-2xl
            md:flex-row-reverse justify-center align-center items-center h-fit"
            key={index}
          >
            <div className="w-full h-fit p-8 rounded-xl">
              <h3 className="font-lato text-3xl font-extrabold leading-12 tracking-wide text-left text-oceanH1">
                {document.title}
              </h3>
              <h4 className="font-lato text-l mt-2 text-oceanH1 ">
                {document.subtitle}
              </h4>

              <div className="w-20 border-2 border-solid border-turquoise my-6" />
              <div className="flex justify-between h-[1Opx] w-[70px] bg-green-divider p-0.5" />
              <p className="text-darkBlue ">{document.text}</p>
              <div className="lg:flex">
                <a
                  href={document.url}
                  download={document.pdf}
                  className="lg:mr-4 lg:mt-6 mt-6 h-10 lg:w-1/6 w-full bg-dark text-white rounded text-base font-bold font-sans flex justify-center items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  {document.button}
                </a>
              </div>
            </div>
            <div className="rounded-xl">
              <img
                src={document.imageUrl}
                key={document.title}
                alt=""
                className="hidden lg:block object-cover rounded-l-xl cursor-pointer"
              />
            </div>
          </div>
        ))}
      </div>

      <ContactInfo />
    </div>
  );
}

export default Bonus;
